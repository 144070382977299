import {
  collection,
  doc,
  DocumentData,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { db } from '../../firebase';
import DnDlist from './DnDlist';
import { Data } from './PortalManagement';

type DnDPortalProps = {
  data: Data;
};

const DnDPortalManagement = ({ data }: DnDPortalProps) => {
  interface ListObjectProps {
    data: DocumentData | undefined;
    id: string;
    prefix: List;
  }
  type List = 'Incomplete' | 'New' | 'InProgress' | 'Completed' | 'Archived';
  const lists: List[] = [
    'Incomplete',
    'New',
    'InProgress',
    'Completed',
    'Archived',
  ];

  const [element, setElement] = useState<Data>(data);

  const removeFromList = (list: ListObjectProps[], index: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);
    return { removed, result };
  };

  const addToList = (
    list: ListObjectProps[],
    index: number,
    element: ListObjectProps
  ) => {
    const result = Array.from(list);
    result.splice(index, 0, element);
    return result;
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const listCopy = { ...element };
    const key = result.source.droppableId;

    const sourceList: ListObjectProps[] = listCopy[key as List] ?? [];
    const { removed: removedElement, result: newSourceList } = removeFromList(
      sourceList,
      result.source.index
    );

    listCopy[key as List] = newSourceList;

    const destinationPrefix = result.destination.droppableId;
    const destinationList = listCopy[destinationPrefix as List];

    if (!destinationList) {
      let newList = { ...listCopy, [destinationPrefix]: [] };
      const newDestinationList = newList[destinationPrefix as List] ?? [];
      listCopy[result.destination.droppableId as List] = addToList(
        newDestinationList,
        result.destination.index,
        removedElement
      );
    } else {
      listCopy[result.destination.droppableId as List] = addToList(
        destinationList,
        result.destination.index,
        removedElement
      );
    }
    setElement(listCopy);
    const responseQuery = query(
      collection(db, 'UserResponse'),
      where('uid', '==', result.draggableId)
    );

    let docs = await getDocs(responseQuery);
    let id = '';
    docs.forEach((docs: any) => {
      id = docs.id;
    });

    const docRef = doc(db, 'UserResponse', id);

    await updateDoc(docRef, {
      stage: result?.destination?.droppableId ?? '',
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err.message));
  };

  return (
    <div className='flex  justify-center items-center'>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className='flex flex-row justify-evenly '>
          {lists.map((list: List) => (
            <DnDlist prefix={list} element={element[list] ?? []} key={list} />
          ))}
        </div>
      </DragDropContext>
    </div>
  );
};

export default DnDPortalManagement;
