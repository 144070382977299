import NavBar from "../HomePage/NavBar";

interface TitleProps {
    title: string;
    questionNumber: string;
}

const Title = ({title, questionNumber}: TitleProps) => {
    return (
        <>
            <div className='mb-[20px] w-screen flex justify-center items-center'>
                <NavBar />
            </div>
            <div className='text-2xl  sm:text-[57px] text-[#58061E]  sm:leading-[68px] text-center'>
                {/*  {`Age Qualification (${points})`} */}
                {title}
            </div>
            <div className='text-6xl sm:text-[80px] leading-[109px] font-bold tracking-normal '>
                <span className='text-[#DED1C5]'>0</span>
                <span className='text-[#AE8625]'>{questionNumber}.</span>
            </div>
        </>
    );
};

export default Title;
