interface EnglishCoursePropType {
  title: string;
}

const EnglishCourse = ({ title }: EnglishCoursePropType) => {
  const _handleMouseOver = () => {};

  return (
    <div className="flex -space-y-1">
      <span className="w-[290px] sm:w-[700px] text-left text-[#5A5A5A] sm:text-xl">
        {title} <span className="sm:hidden">:</span>
      </span>
      {/*  <motion.div
                whileHover={{
                    scale: 1.1,
                    textShadow: "0px 0px 2px #B08662",
                    boxShadow: "0px 0px 5px #B08662",
                    borderRadius: "9999px",
                }}
                onMouseOver={_handleMouseOver}
                className='flex w-3 h-3 m-1 p-2 justify-center items-center border-2 border-[#B08662] rounded-full  cursor-pointer'>
                <FontAwesomeIcon
                    icon={faExclamation}
                    className='text-[#B08662] w-3 h-3'
                />
            </motion.div> */}
    </div>
  );
};

export default EnglishCourse;
