import {PayloadAction} from "@reduxjs/toolkit";
import {
    o1,
    o2,
    o3,
    o4,
    o5,
} from "../../components/AuthPages/Pages/EnglishLanguagePage/EnglishOptions";
import {EnglishCourseType} from "../../components/AuthPages/Pages/EnglishLanguagePage/type";
import {InitialStatePostLoginType} from "./type";

export const SaveEnglishAction = (
    state: InitialStatePostLoginType,
    {payload}: PayloadAction<any>
) => {
    switch (payload?.id) {
        case EnglishCourseType.IELTSAcademic:
            let temp = o1.find((o) => o.label === payload.answer);
            if (temp) {
                state.IELTSAcademic = {
                    ...state.IELTSAcademic,
                    ...temp,
                };
            }
            break;
        case EnglishCourseType.IELTSGeneral:
            let temp1 = o1.find((o) => o.label === payload.answer);
            if (temp1) {
                state.IELTSGeneral = {
                    ...state.IELTSGeneral,
                    ...temp1,
                };
            }
            break;
        case EnglishCourseType.TOEFL:
            let temp2 = o2.find((o) => o.label === payload.answer);
            if (temp2) {
                state.TOEFL = {
                    ...state.TOEFL,
                    ...temp2,
                };
            }
            break;
        case EnglishCourseType.PTE:
            let temp3 = o3.find((o) => o.label === payload.answer);
            if (temp3) {
                state.PTE = {
                    ...state.PTE,
                    ...temp3,
                };
            }
            break;
        case EnglishCourseType.OET:
            let temp4 = o4.find((o) => o.label === payload.answer);
            if (temp4) {
                state.OET = {
                    ...state.OET,
                    ...temp4,
                };
            }
            break;
        case EnglishCourseType.CAMBRIDGE:
            let temp5 = o5.find((o) => o.label === payload.answer);
            if (temp5) {
                state.CAMBRIDGE = {
                    ...state.CAMBRIDGE,
                    ...temp5,
                };
            }
            break;

        default:
            break;
    }
};

export const SaveEnglishYearAction = (
    state: InitialStatePostLoginType,
    {payload}: PayloadAction<any>
) => {
    switch (payload?.id) {
        case EnglishCourseType.IELTSAcademic:
            if (state.IELTSAcademic)
                state.IELTSAcademic = {
                    ...state.IELTSAcademic,
                    year: payload.year,
                };
            break;
        case EnglishCourseType.IELTSGeneral:
            if (state.IELTSGeneral)
                state.IELTSGeneral = {
                    ...state.IELTSGeneral,
                    year: payload.year,
                };
            break;
        case EnglishCourseType.TOEFL:
            if (state.TOEFL) {
                state.TOEFL = {
                    ...state.TOEFL,
                    year: payload.year,
                };
            }

            break;
        case EnglishCourseType.PTE:
            if (state.PTE) {
                state.PTE = {
                    ...state.PTE,
                    year: payload.year,
                };
            }
            break;
        case EnglishCourseType.OET:
            if (state.OET) {
                state.OET = {
                    ...state.OET,
                    year: payload.year,
                };
            }
            break;
        case EnglishCourseType.CAMBRIDGE:
            if (state.CAMBRIDGE) {
                state.CAMBRIDGE = {
                    ...state.CAMBRIDGE,
                    year: payload.year,
                };
            }
            break;
        default:
            break;
    }
};

export const SaveIndustryAction = (
    state: InitialStatePostLoginType,
    {payload}: PayloadAction<any>
) => {
    state.Industry = payload;
};

export const SaveSpecialisationAction = (
    state: InitialStatePostLoginType,
    {payload}: PayloadAction<any>
) => {
    state.Specialisation = payload;
};

export const SaveEducationAction = (
    state: InitialStatePostLoginType,
    {payload}: PayloadAction<any>
) => {
    state.Education = payload;
};

export const SaveDetailedSpecAction = (
    state: InitialStatePostLoginType,
    {payload}: PayloadAction<any>
) => {
    state.DetailedSpec = payload;
};
