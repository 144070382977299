import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, {Dispatch, SetStateAction, useMemo, useState} from "react";
import {useMatch} from "react-router-dom";
import {Q2OptionsType} from "../../Questions/type";
import DropdownContainer from "./DropdownContainer";

interface DropdownProps {
    value: string | null;
    isOpen: boolean;
    options: Q2OptionsType[];
    children?: React.ReactNode;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setValue: Dispatch<SetStateAction<string | null>>;
    defaultValue?: string;

    setSize?: boolean;
}

export default function Dropdown({
    children,
    isOpen,
    value,
    options,
    setIsOpen,
    setValue,
    defaultValue,
    setSize,
}: DropdownProps) {
    const [init, setInit] = useState(value);

    const newOptions = useMemo(() => options, [options]);
    const parm = useMatch("/:page");
    const page = parm?.params.page;
    // console.log(value);
    return (
        <>
            <div
                onClick={() => setIsOpen(!isOpen)}
                className={classNames(
                    "relative select-none flex flex-row justify-between items-center border border-[#DED1C5] bg-white",
                    {
                        "w-56 sm:h-[45px] sm:w-[305px]": !setSize,
                        "w-64 sm:h-[36px] sm:w-[350px]": setSize,
                    }
                )}>
                {value ? (
                    <div className='pl-2 sm:pl-[10px] sm:text-xl text-[#A09FA1] w-[210px] sm:w-[300px] truncate'>
                        {value}
                    </div>
                ) : (
                    <div className='pl-2 sm:pl-[10px] sm:text-xl text-[#A09FA1] w-[210px] sm:w-[300px] truncate'>
                        {defaultValue ? defaultValue : children}
                    </div>
                )}
                {/* This is icon button */}
                <div
                    className={classNames(
                        "absolute right-0 flex justify-center items-center bg-[#B08662] m-0 p-0 h-full  cursor-pointer",
                        {
                            "w-7 sm:w-[45px]": !setSize,
                            "w-7 sm:w-[36px]": setSize,
                        }
                    )}>
                    {isOpen ? (
                        <FontAwesomeIcon
                            icon={faCaretUp}
                            className={" text-white"}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faCaretDown}
                            className={" text-white "}
                        />
                    )}
                </div>
                {/* End of  icon button */}

                {/* Dropdown Container */}
                {isOpen && (
                    <DropdownContainer
                        onChange={setValue}
                        options={newOptions}
                        value={value}
                        setIsOpen={setIsOpen}
                        setSize={setSize}
                    />
                )}
            </div>
        </>
    );
}
