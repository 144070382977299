import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {saveDetailedSpec} from "../../../../feature/PostLogin/postLoginSlice";
import UseDropdownHook from "../../../Customhook/DropdownCustomhook";
import Dropdown from "../../../DropdownMenu/Dropdown/Dropdown";
import {Q2OptionsType} from "../../../Questions/type";
import {
    broadOpt,
    broadOption,
    EngineeringNarrowOpt,
    ItNarrowOpt,
    ScienceNarrowOpt,
} from "./Options";

const DetailedSpecialisation = () => {
    const broadData = UseDropdownHook();
    const narrowData = UseDropdownHook();
    const dispatch = useDispatch();
    const [narrowOptions, setNarrowOptions] = useState<Q2OptionsType[]>([]);

    useEffect(() => {
        switch (broadData.value) {
            case broadOption.science:
                setNarrowOptions(ScienceNarrowOpt);
                narrowData.setValue("Select the most applicable answer");
                break;
            case broadOption.it:
                setNarrowOptions(ItNarrowOpt);
                narrowData.setValue("Select the most applicable answer");
                break;
            case broadOption.engineering:
                setNarrowOptions(EngineeringNarrowOpt);
                narrowData.setValue("Select the most applicable answer");
                break;

            default:
                break;
        }
    }, [broadData.value]);

    useEffect(() => {
        dispatch(saveDetailedSpec(undefined));
        if (narrowData.value || broadData.value)
            dispatch(
                saveDetailedSpec({
                    broad: broadData.value,
                    narrow: narrowData.value,
                })
            );
    }, [dispatch, narrowData, broadData]);

    return (
        <div className='flex px-2 sm:px-0 flex-col sm:w-[1082] justify-center items-center sm:space-y-10 sm:py-36 '>
            <div className='flex flex-col space-y-2 pb-2 sm:space-y-0 items-center justify-center sm:flex-row sm:space-x-10 sm:pb-0'>
                <div className=' text-center text-[#5A5A5A] sm:text-3xl '>
                    Please detail your broad field of Specialisation
                </div>
                <Dropdown
                    {...broadData}
                    options={broadOpt}
                    setSize
                    defaultValue=' Select the most applicable answer'
                />
            </div>
            <div className='flex flex-col  space-y-2   pb-2 sm:space-y-0 sm:flex-row items-center justify-center sm:space-x-7 sm:pb-0'>
                <div className='text-center text-[#5A5A5A] sm:text-3xl'>
                    Please detail your narrow field of Specialisation
                </div>

                <Dropdown
                    {...narrowData}
                    options={narrowOptions}
                    setSize
                    defaultValue=' Select the most applicable answer'
                />
            </div>
        </div>
    );
};

export default DetailedSpecialisation;
