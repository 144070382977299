import {Form, Formik} from "formik";
import {motion} from "framer-motion";
import {useState} from "react";
import {Link} from "react-router-dom";
import * as Yup from "yup";
import {forgotPassword} from "../../../firebase";
import FormikControl from "../../SignUp/FormikControl";

const ForgotPassword = () => {
    const [passwordResetSent, setPasswordResetSent] = useState(false);
    const initialValues = {
        email: "",
    };
    const validationSchema = Yup.object({
        email: Yup.string().required("Required"),
    });

    const onSubmit = async (values: any, resetForm: any) => {
        forgotPassword(values.email)
            .then((err) => {
                setPasswordResetSent(true);
                resetForm();
            })
            .catch((err) => {
                alert(err);
            });
    };
    return (
        <div className='relative flex justify-center items-center '>
            {passwordResetSent && (
                <div className='absolute -bottom-20  sm:top-20'>
                    <span className='text-green-700 font-bold'>
                        Please check your email to reset the password
                    </span>
                </div>
            )}
            <div className='p-8 flex justify-center items-center border border-[#B08662] bg-white sm:p-20 sm:w-[466px] sm:h-[428px]'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(v, {resetForm}) => onSubmit(v, resetForm)}
                    validationSchema={validationSchema}>
                    <Form className='flex flex-col items-center space-y-3'>
                        <FormikControl
                            control='SignInput'
                            type='email'
                            name='email'
                            placeholder='email'
                        />

                        <button type='submit'>
                            <motion.div
                                role={"presentation"}
                                whileHover={{
                                    textShadow: "0px 0px 8px rgb(255,255,255)",
                                    boxShadow: "0px 0px 8px rgb(255,255,255)",
                                    borderRadius: "9999px",
                                }}
                                className='px-14 sm:w-[305px] flex flex-row justify-center font-se items-center p-1   bg-[#58061E] opacity-100 text-white text-lg tracking-normal rounded-full cursor-pointer'>
                                Reset
                            </motion.div>
                        </button>
                        <div className='flex flex-row  text-[#707070]'>
                            Not what you're after?
                            <Link to='/signin'>
                                <span className='text-[#58061E] font-bold pl-1'>
                                    Sign In
                                </span>
                            </Link>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default ForgotPassword;
