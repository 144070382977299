import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../store";

export const excemptCountries = [
    "United Kingdom",
    "United States",
    "Canada",
    "New Zealand",
    "Ireland",
];

export const visibleSelectors = createSelector(
    (state: Pick<RootState, "modal">) => state.modal.show,
    (show) => show
);

export const buttonModalSelector = createSelector(
    (state: Pick<RootState, "modal">) => state.modal,
    ({buttonType, question}) => ({
        buttonType,
        question,
    })
);

export const summarySelector = createSelector(
    (state: Pick<RootState, "modal">) => state.modal,
    ({age, countries, workExperience, degree}) => ({
        age,
        countries,
        workExperience,
        degree,
    })
);

export const checkSelectedCountries = createSelector(
    (state: Pick<RootState, "modal">) => state.modal,
    ({countries, numOfCountries}) => {
        let isExemptCountry;
        if (countries.length === numOfCountries) {
            isExemptCountry = excemptCountries.find((country) =>
                countries.includes(country)
            );
        }

        return {match: countries.length === numOfCountries, isExemptCountry};
    }
);

export const currentPoints = createSelector(
    (state: Pick<RootState, "modal">) => state.modal.points,
    (points) => points
);

export const SaveResponseSelector = createSelector(
    (state: Pick<RootState, "modal">) => state.modal,
    (data) => data
);

export const ProfessionSelector = createSelector(
    (state: Pick<RootState, "modal">) => state.modal.selectedProfession,
    (data) => data
);

export const selectedCountrySelector = createSelector(
    (state: Pick<RootState, "modal">) => state.modal.countries,
    (data) => data
);
