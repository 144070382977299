import {useEffect, useState} from "react";
import DropdownCountries from "../DropdownMenu/DropdownCountries";

interface Q2SubProps {
    selectRef: React.RefObject<HTMLSelectElement>;
    id: string;
    value?: string;
}

const Q2Sub = ({selectRef, id, value}: Q2SubProps) => {
    const [countries, setCountries] = useState<string[]>([]);
    const [loading, setloading] = useState(false);
    useEffect(() => {
        setloading(true);
        const getCountries = async () => {
            const response = await fetch("https://restcountries.com/v3.1/all");
            const data = await response.json();

            const listOfCountries = data
                ?.filter((d: any) => d.name.common !== "Australia")
                ?.map((d: any) => d.name.common)
                ?.sort();

            setCountries(listOfCountries);
            setloading(false);
        };
        getCountries();
    }, []);

    return (
        <DropdownCountries
            options={countries}
            selectRef={selectRef}
            id={id}
            value={value}
            loading={loading}
        />
    );
};

export default Q2Sub;
