import {Field, FieldAttributes} from "formik";

function TextArea(props: FieldAttributes<any>) {
    const {label, placeholder, name, children, ...rest} = props;
    return (
        <div>
            <div className='flex flex-col  items-center justify-center sm:flex-row sm:items-start '>
                <label
                    htmlFor={name}
                    className='text-lg sm:text-xl sm:w-1/3 text-[#5A5A5A]'>
                    {label}
                </label>
                <Field
                    as='textarea'
                    id={name}
                    name={name}
                    {...rest}
                    className='text-base p-1 w-56 h-32 bg-white border border-[#DED1C5] outline-none sm:w-[350px] sm:py-2.5 sm:pl-4 sm:text-lg sm:ml-5 text-[#A09FA1] sm:h-32 whitespace-pre-wrap'
                    placeholder={placeholder}>
                    {children}
                </Field>
            </div>

            {/*  <ErrorMessage component={TextError} name={name} /> */}
        </div>
    );
}

export default TextArea;
