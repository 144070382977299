import {useSelector} from "react-redux";
import {UserNameSelector} from "../../../../feature/AuthSelector";
import {RootState} from "../../../../feature/store";

const WelcomeUser = () => {
    const isAdmin = useSelector((state: RootState) => state.auth.admin);
    const userName = useSelector(UserNameSelector);
    return (
        <div className='font-bold mx-2 lg:text-3xl  flex justify-center items-center text-[#5A5A5A]'>
            {isAdmin ? "Welcome , Admin" : `Welcome back, ${userName}`}
        </div>
    );
};

export default WelcomeUser;
