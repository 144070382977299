import React from "react";
import { useDispatch } from "react-redux";
import { saveEnglishYear } from "../../../../feature/PostLogin/postLoginSlice";

interface EnglishYearProps {
  id?: string;
  year: number | undefined;
  setYear: (y: number) => void;
}

const EnglishYear = ({ id, year, setYear }: EnglishYearProps) => {
  const dispatch = useDispatch();
  const _handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setYear(+e.currentTarget.value);

    dispatch(
      saveEnglishYear({
        year: +e.currentTarget.value,
        id: e.currentTarget.id,
      })
    );
  };
  return (
    <input
      id={id}
      type="number"
      value={year}
      placeholder="Year Completed"
      onChange={_handleOnChange}
      className="text-sm w-32 text-center border border-[#DED1C5] outline-none sm:w-[150px] sm:py-0.5  sm:text-lg"
    />
  );
};

export default EnglishYear;
