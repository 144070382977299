import CheckBox from "../CheckBox";

const ICTSupportEngineer = () => {
  return (
    <div className="flex flex-col justify-center items-center self-center space-y-4 capitalize-first sm:w-2/3 max-w-screen-lg">
      <div className="sm:text-xl lg:text-3xl xl:text-4xl text-[#5A5A5A] text-center">Select the professional duties you can demonstrate that you regularly perform as an ICT Support Engineer</div>

      <CheckBox>scheduling and conducting quality audit inspections, and analyzing and reviewing systems, data and documentation</CheckBox>
      <CheckBox>identifying variations and potential high risk areas in securing adherence to standards and procedures</CheckBox>
      <CheckBox>recommending corrective action plans and improvements in the resolution of non-compliance with standards detected through monitoring and auditing of processes and procedures</CheckBox>
      <CheckBox>communicating, educating and liaising with users and management to ensure awareness and adherence to standards, procedures and quality control issues and activities</CheckBox>
      <CheckBox>assisting in troubleshooting, diagnosing, testing and resolving system problems and issues</CheckBox>
      <CheckBox>developing, conducting and providing technical guidance and training in application software and operational procedures</CheckBox>
      <CheckBox>
        analyzing, evaluating and diagnosing technical problems and issues such as installation, maintenance, repair, upgrade and configuration and troubleshooting of desktops, software, hardware,
        printers, Internet, email, databases, operating systems and security systems
      </CheckBox>
      <CheckBox>
        testing, identifying and diagnosing functionality errors and faults in systems, and programming code within established testing protocols, guidelines and quality standards to ensure systems
        perform to specification
      </CheckBox>
      <CheckBox>
        established testing protocols, guidelines and quality standards to ensure systems perform to specification performing organizational systems architecture reviews and assessments, and
        recommending current and future hardware and software strategies and directions
      </CheckBox>
      <CheckBox>
        creating and reviewing technical documentation such as procedural, instructional and operational guides and manuals, technical reports and specifications and maintenance inventory systems
      </CheckBox>
    </div>
  );
};

export default ICTSupportEngineer;
