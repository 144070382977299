import { init, track } from "fbq";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { activePage, pushHistory } from "../../feature/QSlice/QSlice";
import NavBar from "./NavBar";

const WelcomPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    init("723342295671008");
    track("PageView");
  }, []);

  useEffect(() => {
    dispatch(pushHistory({ id: 0, name: "welcome" }));
    dispatch(activePage({ id: 0, name: "welcome" }));
  }, [dispatch]);

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        y: -250,
        opacity: 0,
      }}
      className="h-screen  w-screen"
      id="home"
    >
      <div className="px-5 flex flex-col justify-center items-center space-y-4  text-gray-700 text-center text-base    tracking-[6.6px] sm:px-0 sm:text-4xl sm:space-y-[60px]  lg:text-[35px] lg:leading-[75px] 2xl:leading-[70px] 2xl:space-y-[60px] 2x:mx-[120px]">
        <div className="h-32 sm:h-[170px] w-screen  flex justify-center items-center">
          <NavBar />
        </div>
        <p className=" font-semibold flex flex-col justify-center items-center">
          {"Welcome to the skilled migrant "}
          <span>pre-qualification questionnaire. </span>
        </p>
        <p className="font-semibold sm:w-2/3">
          Your answers to this questionnaire will help us assess whether you
          have an independent pathway to obtain permanent residence in
          Australia.
        </p>
        <div className="flex flex-col justify-center items-center space-y-1">
          <motion.div
            whileHover={{
              scale: 1.1,
              textShadow: "0px 0px 8px rgb(255,255,255)",
              boxShadow: "0px 0px 8px rgb(255,255,255)",
              borderRadius: "9999px",
            }}
          >
            <Link
              to="/q1"
              className="w-32 h-10 text-base flex flex-row justify-center  items-center sm:h-[67px] sm:w-[277px] bg-[#58061E] opacity-100 text-white sm:text-2xl tracking-normal rounded-full"
            >
              Continue
            </Link>
          </motion.div>
          <span className="text-sm  sm:text-2xl sm:w-[500px]  tracking-normal leading-none py-2">
            Already completed the preliminary questions and have created an
            account?
            <Link to="/signin" className="text-[#58061E] font-bold pl-1">
              Sign In
            </Link>
          </span>
        </div>
      </div>
    </motion.div>
  );
};

export default WelcomPage;
