import CheckBox from "../CheckBox";

const ICTSecSpecialist = () => {
  return (
    <div className="flex flex-col justify-center items-center self-center space-y-4 capitalize-first sm:w-2/3 max-w-screen-lg">
      <div className="sm:text-xl lg:text-3xl xl:text-4xl text-[#5A5A5A] text-center">Select the professional duties you can demonstrate that you regularly perform as an ICT Security Specialist</div>

      <CheckBox>
        designing and maintaining database architecture, data structures, tables, dictionaries and naming conventions to ensure the accuracy and completeness of all data master files
      </CheckBox>
      <CheckBox>performing the operational establishment and preventive maintenance of backups, recovery procedures, and enforcing security and integrity controls</CheckBox>
      <CheckBox>implementing and administering database documentation, guidelines, policies and procedures</CheckBox>
      <CheckBox>
        testing database systems and upgrades, such as debugging, tracking, reproduction, logging and resolving all identified problems, according to approved quality testing scripts, procedures and
        processes
      </CheckBox>
      <CheckBox>accepting responsibility for the processes, procedures and operational management associated with system security and disaster recovery planning</CheckBox>
      <CheckBox>
        liaising with security vendors, suppliers, service providers and external resources; analysing, recommending, installing and maintaining software security applications; and monitoring
        contractual obligations, performance delivery and service level agreements
      </CheckBox>
      <CheckBox>
        troubleshooting and providing service support in diagnosing, resolving and repairing server-related hardware and software malfunctions, encompassing workstations and communication
        infrastructure
      </CheckBox>
      <CheckBox>preparing and maintaining documentation, policies and instructions, and recording and detailing operational procedures and system logs</CheckBox>
      <CheckBox>ensuring that the design of computer sites allows all components to fit together and work properly, and monitoring and adjusting the performance of networks</CheckBox>
      <CheckBox>
        continually surveying the current computer site to determine future network needs and making recommendations for enhancements in the implementation of future servers and networks
      </CheckBox>
    </div>
  );
};

export default ICTSecSpecialist;
