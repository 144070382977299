import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import { saveEnglishAnswer } from "../../feature/PostLogin/postLoginSlice";

interface OptionsType {
  label: string;
  competency?: "Competent" | "Proficient" | "Superior";
  points?: number;
}

interface CustomDropdownPropsType {
  id?: string;
  value: string | null;
  options?: OptionsType[];
  setValue: (v: string) => void;
}

const CustomDropdown = ({ id, value, options, setValue }: CustomDropdownPropsType) => {
  const dispatch = useDispatch();
  const _handleOnChange = (e: React.FormEvent<HTMLSelectElement>) => {
    e.preventDefault();
    //console.log(e.currentTarget.id);
    setValue(e.currentTarget.value);

    dispatch(
      saveEnglishAnswer({
        answer: e.currentTarget.value,
        id: e.currentTarget.id,
      })
    );
  };
  return (
    <div className="relative border border-solid border-[#DED1C5] h-6 w-72 sm:w-[356px] sm:h-[36px] bg-white">
      <select
        id={id}
        className="     cursor-pointer
                        appearance-none
                        sm:text-xl
                        rounded
                        outline-none
                        z-10
                        h-8
                        w-72
                        pl-2
                        pr-5
                        sm:w-[456px]
                        sm:h-[45px]
                        text-[#A09FA1]
                        absolute
                        sm:pl-[10px]
                        sm:pr-[40px]
                        pb-[10px]
                        bg-transparent
                        truncate
                        "
        onChange={_handleOnChange}
        value={!value ? "Select the most applicable answer" : value}
      >
        <option
          value={"Select the most applicable answer"}
          className="w-7"
        >
          Select the most applicable answer
        </option>
        {options?.map((o, i) => (
          <option
            key={i}
            value={o.label}
            className="text-[#707070] odd:bg-[#FCFAF9] border border-t-[#E6DBD0] truncate"
            children={o.label}
          />
        ))}
      </select>
      <div className="absolute right-0  flex justify-center items-center bg-[#B08662] m-0 p-0 h-[23px] w-6 sm:h-[35px] sm:w-[36px] cursor-pointer">
        <FontAwesomeIcon
          icon={faCaretDown}
          className={"text-white"}
        />
      </div>
    </div>
  );
};

export default CustomDropdown;
