import {createSelector} from "@reduxjs/toolkit";
import {DocumentData} from "firebase/firestore";
import {RootState} from "./store";

export const isAuthSelector = createSelector(
    (state: Pick<RootState, "auth">) => state.auth.isAuth,
    (auth) => auth
);

export const UserNameSelector = createSelector(
    (state: Pick<RootState, "auth">) => state.auth.userCredential,
    (userCredential) => {
        if (userCredential) {
            const {firstName, surname} = userCredential;
            return `${firstName} ${surname}`;
        }

        return null;
    }
);

export const ResponseSelector = createSelector(
    (state: Pick<RootState, "auth">) => state.auth,
    ({response, userCredential}) => {
        const data: DocumentData = {
            firstName: userCredential?.firstName,
            surname: userCredential?.surname,
            phone: userCredential?.phone,
            email: userCredential?.email,
            uid: userCredential?.uid,
            workExperience: response?.workExperience,
            countries: response?.countries,
            age: response?.age,
            address: userCredential?.address,
            city: userCredential?.city,
            postcode: userCredential?.postcode,
            state: userCredential?.state,
        };

        return data;
    }
);

export const UserIDSelector = createSelector(
    (state: Pick<RootState, "auth">) => state.auth.docID,
    (id) => id
);
