import { signOut } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../../feature/AuthSlice';
import { resetPostLogin } from '../../../../feature/PostLogin/postLoginSlice';
import { auth } from '../../../../firebase';
import Button from '../Button/Button';
import APLLogo from './APLLogo';
import WelcomeUser from './WelcomeUser';

interface HeaderPropsType {
  enableWelcomeUser?: boolean;
  enableLogoutBtn?: boolean;
  enableSaveBtn?: boolean;
  enableCSVButton?: boolean;
}

const Header = ({
  enableWelcomeUser,
  enableLogoutBtn,
  enableSaveBtn,
  enableCSVButton,
}: HeaderPropsType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth);
    navigate('/signin');
    dispatch(logout());
    dispatch(resetPostLogin());
    window.location.reload();
  };

  const handleSave = () => {
    // console.log("Click");
  };
  const handleCSV = () => {
    // console.log("click csv");
  };
  return (
    <div className=' flex flex-col px-4 sm:flex-none sm:px-0 sm:grid sm:grid-cols-3 justify-center items-center mt-12 '>
      <div className='order-1 pt-4 sm:pt-0 sm:order-none'>
        {enableWelcomeUser && <WelcomeUser />}
      </div>
      <APLLogo />
      <div className='order-2  pt-4 sm:pt-0 sm:order-none flex flex-col space-y-2 sm:mx-3 justify-center  items-center  lg:space-x-3.5 lg:flex-row lg:space-y-0'>
        {enableSaveBtn && (
          <Button title='Save Application' onClick={handleSave} />
        )}
        {enableCSVButton && (
          <Button title='Download to CSV' onClick={handleCSV} />
        )}
        {enableLogoutBtn && <Button title='Logout' onClick={handleLogout} />}
      </div>
    </div>
  );
};

export default Header;
