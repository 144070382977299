import { collection, DocumentData, query } from 'firebase/firestore';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { save } from '../../feature/Portal/PortalSlice';
import PageContainer from '../AuthPages/PageContainer';
import { columns } from './columns';
import DnDPortalManagement from './DnDPortalManagement';
import ProfileModal from './ProfileModal';
import Table from './Table';

type List = 'Incomplete' | 'New' | 'InProgress' | 'Completed' | 'Archived';
interface ListObjectProps {
  data: DocumentData | undefined;
  id: string;
  prefix: List;
}
export type Data = {
  [x in List]?: ListObjectProps[];
};

const PortalManagement = () => {
  const [data, setData] = useState<Data | null>(null);
  const [dataTable, setDataTable] = useState<Array<any> | null>(null);
  const db = useFirestore();
  const userQuery = query(collection(db, 'users'));
  const { data: UserData } = useFirestoreCollectionData(userQuery);
  const responseQuery = query(collection(db, 'UserResponse'));
  const { data: ResponseData } = useFirestoreCollectionData(responseQuery);
  const dispatch = useDispatch();
  //console.log("User=>", UserData);
  //console.log("Response=>", ResponseData);

  useEffect(() => {
    const newData = UserData.filter((d) => Boolean(d.uid)).map((user) => {
      const userResponse = ResponseData.find(
        (response) => response.uid === user.uid
      );

      return { ...user, userResponse };
    });

    dispatch(save(newData));
    const dataWithTotalPoints = newData.map((data) => {
      let isPostPoints = data.userResponse?.postAnswer?.currentPoints;
      let prePoints = data.userResponse?.points;

      if (isPostPoints) {
        let education = isPostPoints.education ?? 0;
        let englang = isPostPoints.englang?.points ?? 0;
        let specialisation = isPostPoints.specialisationPoints;
        return {
          ...data,
          totalPoints: prePoints + education + englang + specialisation,
        };
      }
      return { ...data, totalPoints: prePoints };
    });

    setDataTable(dataWithTotalPoints);
    const testing = _.groupBy(
      newData,
      (data) => data.userResponse?.stage ?? 'Incomplete'
    );

    const sortedData = _.mapValues(testing, (d) => {
      let newData = d.map((e: any) => {
        return {
          data: e,
          id: e.userResponse?.uid,
          prefix: e.userResponse?.stage ?? 'Incomplete',
        };
      });

      return newData;
    });

    setData(sortedData);
  }, [UserData, ResponseData, dispatch]);
  //console.log({data});
  return (
    <PageContainer title='Management Portal' enableLogoutBtn enableWelcomeUser>
      {dataTable && <Table columnsProps={columns} dataProps={dataTable} />}
      {data && <DnDPortalManagement data={data} />}
      <ProfileModal />
    </PageContainer>
  );
};

export default PortalManagement;
