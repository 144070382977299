import {ErrorMessage, Field, FieldAttributes} from "formik";
import TextError from "./TextError";

const PathwayInput = (props: FieldAttributes<any>) => {
    const {label, placeholder, name, ...rest} = props;
    return (
        <div className='w-full text-[#5A5A5A]'>
            <div className='flex flex-col sm:flex-row items-center justify-between '>
                <label
                    htmlFor={name}
                    className='sm:text-xl font-semibold whitespace-pre-line'>
                    {label}
                </label>
                <Field
                    id={name}
                    name={name}
                    {...rest}
                    className='w-[200px] px-2 py-1 border border-[#DED1C5] outline-none  sm:py-2.5 sm:px-4 sm:text-lg sm:ml-5 sm:w-[300px]'
                    placeholder={placeholder}
                />
            </div>

            <ErrorMessage component={TextError} name={name} />
        </div>
    );
};

export default PathwayInput;
