const ModalContent = () => {
    return (
        <div className='flex flex-col p-1 items-start space-y-6 sm:text-2xl text-[#707070] sm:w-[800px] sm:pt-4 sm:pb-16'>
            <div className='font-bold'>Information Technology</div>
            <div>This industry includes the following roles:</div>
            <div className='flex flex-col sm:flex-row sm:space-x-5'>
                <div className='flex flex-col'>
                    <span>- Analyst Programmer</span>
                    <span>- Computer Network and Systems Engineer</span>
                    <span>- Database Administrator</span>
                    <span>- Developer Programmer</span>
                    <span>- ICT Business Analyst</span>
                    <span>- ICT Project Manager</span>
                </div>
                <div className='flex flex-col'>
                    <span>- ICT Security Specialist</span>
                    <span>- ICT Support Engineer</span>
                    <span>- Multimedia Specialist</span>
                    <span>- Network Analyst</span>
                    <span>- Software Engineer </span>
                    <span>- Systems Analyst</span>
                </div>
            </div>
        </div>
    );
};

export default ModalContent;
