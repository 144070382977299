import {DocumentData} from "firebase/firestore";
import {Droppable} from "react-beautiful-dnd";
import Scrollbars from "react-custom-scrollbars";
import DndListItem from "./DndListItem";

type List = "Incomplete" | "New" | "InProgress" | "Completed" | "Archived";
interface ListObjectProps {
    data: DocumentData | undefined;
    id: string;
    prefix: List;
}
interface DnDColumnProps {
    prefix: string;
    element?: ListObjectProps[];
    key: string;
}

const DnDlist = ({prefix, element}: DnDColumnProps) => {
    return (
        <div className='p-2 border min-h-full w-[350px]'>
            <div className='text-lg pb-2 font-extrabold text-[#5A5A5A] text-center'>
                {prefix}
            </div>
            <div className='h-[550px] p-1 m-1'>
                <Scrollbars>
                    <Droppable droppableId={prefix}>
                        {(provided) => (
                            <div
                                className='flex flex-col space-y-4 px-4'
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {element?.map((item, index) => (
                                    <DndListItem
                                        key={item.id}
                                        item={item}
                                        index={index}
                                    />
                                ))}
                                <div className='h-96'>
                                    {provided.placeholder}
                                </div>
                            </div>
                        )}
                    </Droppable>
                </Scrollbars>
            </div>
        </div>
    );
};

export default DnDlist;
