import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../firebase";
import {
    SaveDetailedSpecAction,
    SaveEducationAction,
    SaveEnglishAction,
    SaveEnglishYearAction,
    SaveIndustryAction,
    SaveSpecialisationAction,
} from "./actions";
import {InitialStatePostLoginType} from "./type";

export const savePostAnswer = createAsyncThunk(
    "post/postAnswer",
    async (id: string, thunkAPI) => {
        const state: any = thunkAPI.getState();
        const {loading, status, ...postAnswer} = state?.post;
        const {selectedProfession} = state?.modal;
        const docRef = doc(db, "UserResponse", id);
        await updateDoc(docRef, {
            postAnswer,
            profession: selectedProfession,
        });
    }
);

const initialState: InitialStatePostLoginType = {
    loading: false,
    status: "pending",
    englishLanguage: null,
    IELTSAcademic: null,
    IELTSGeneral: null,
    TOEFL: null,
    PTE: null,
    OET: null,
    CAMBRIDGE: null,
    Industry: undefined,
    Specialisation: undefined,
    Education: undefined,
    currentPoints: {
        education: 0,
        englang: {
            points: 0,
        },
        specialisationPoints: 0,
    },
};

const PostSlice = createSlice({
    name: "post",
    initialState,
    reducers: {
        calculatePoints: (
            state: InitialStatePostLoginType,
            {payload}: PayloadAction<any>
        ) => {
            const key = Object.keys(payload)[0];

            switch (key) {
                case "education":
                    state.currentPoints.education = payload.education;
                    break;
                case "englang":
                    state.currentPoints.englang = payload.englang;
                    break;
                case "specialisationPoints":
                    state.currentPoints.specialisationPoints =
                        payload.specialisationPoints;
                    break;
                default:
                    break;
            }
        },
        saveEnglishAnswer: SaveEnglishAction,
        saveEnglishYear: SaveEnglishYearAction,
        saveIndustry: SaveIndustryAction,
        saveSpecialisation: SaveSpecialisationAction,
        saveEducation: SaveEducationAction,
        saveDetailedSpec: SaveDetailedSpecAction,
        resetPostLogin: (state: InitialStatePostLoginType) =>
            (state = initialState),
    },

    extraReducers: (builder) => {
        builder.addCase(savePostAnswer.fulfilled, (state, {payload}) => {
            state.loading = false;
            state.status = "successful";
        });
        builder.addCase(savePostAnswer.pending, (state, {payload}) => {
            state.loading = true;
            state.status = "pending";
        });
        builder.addCase(savePostAnswer.rejected, (state, {payload}) => {
            state.loading = false;
            state.status = "error";
        });
    },
});

export const {
    saveEnglishAnswer,
    saveEnglishYear,
    saveIndustry,
    saveSpecialisation,
    saveEducation,
    saveDetailedSpec,
    resetPostLogin,
    calculatePoints,
} = PostSlice.actions;
export default PostSlice.reducer;
