import classNames from "classnames";
import {Dispatch, SetStateAction} from "react";
import {Q2OptionsType} from "../../Questions/type";
interface DropdownTrayPros {
    onChange: Dispatch<SetStateAction<null | string>>;
    label: Q2OptionsType;
    selected?: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setSize?: boolean;
}

const DropdownTray = ({
    onChange,
    label,
    selected,
    setIsOpen,
    setSize,
}: DropdownTrayPros) => {
    return (
        <div
            className={classNames(
                "cursor-pointer   odd:bg-[#FCFAF9] border border-t-[#E6DBD0] text-[#707070] ",
                {
                    "px-1 sm:text-2xl sm:px-[2px] sm:py-2": !setSize,
                    "sm:text-lg sm:px-[4px] sm:py-2": setSize,
                }
            )}
            onClick={() => {
                onChange(label.value);
                setIsOpen(false);
            }}>
            {label.label}
        </div>
    );
};

export default DropdownTray;
