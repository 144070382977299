import APLImage from "../../images/APL-black-600px.png";

const NavBar = () => {
    return (
        <div className='relative mx-2  h-[95px] w-[600px] mt-10 flex justify-center '>
            <img src={APLImage} alt='APL_Logo' className='absolute ' />
        </div>
    );
};

export default NavBar;
