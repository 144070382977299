import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { addDuties, removeDuties } from "../../../feature/Modal/modalSlice";

interface CheckBoxPropsType {
  children: React.ReactNode;
}

const CheckBox = ({ children }: CheckBoxPropsType) => {
  const ref = useRef<HTMLInputElement>(null);
  const param = useParams();
  const dispatch = useDispatch();

  const handleChange = () => {
    if (param?.dutiesID && ref.current?.labels) {
      const duty = ref.current?.labels[0]?.innerText;
      const id = param?.dutiesID;
      const isChecked = ref.current?.checked;
      if (isChecked) dispatch(addDuties({ duty, id }));
      else {
        dispatch(removeDuties({ duty, id }));
      }
    }
  };

  return (
    <>
      <div className="w-full px-6 text-[#707070] border-b border-[#B08662] py-2">
        <label className="grid grid-cols-4 justify-items-stretch">
          <div className="sm:text-base lg:text-xl xl:text-2xl col-span-3 sm:max-w-md md:max-w-none select-none capitalize-first">{children}</div>
          <div className="w-7 h-7 sm:w-10 sm:h-10 col-span-1 justify-self-end">
            <input
              id="1"
              name="2"
              ref={ref}
              type={"checkbox"}
              className=" accent-[#B88E27] w-full h-full "
              onChange={handleChange}
            />
          </div>
        </label>
      </div>
    </>
  );
};

export default CheckBox;
