import CheckBox from "../CheckBox";

const NetworkAnalyst = () => {
  return (
    <div className="flex flex-col justify-center items-center self-center space-y-4 capitalize-first sm:w-2/3 max-w-screen-lg">
      <div className="sm:text-xl lg:text-3xl xl:text-4xl text-[#5A5A5A] text-center">Select the professional duties you can demonstrate that you regularly perform as a Network Analyst</div>
      <CheckBox>
        analyzing, developing, interpreting and evaluating complex system design and architecture specifications, data models and diagrams in the development, configuration and integration of computer
        systems
      </CheckBox>
      <CheckBox>researching, analyzing, evaluating and monitoring network infrastructure to ensure networks are configured to operate at optimal performance</CheckBox>
      <CheckBox>assessing and recommending improvements to network operations and integrated hardware, software, communications and operating systems</CheckBox>
      <CheckBox>providing specialist skills in supporting and troubleshooting network problems and emergencies</CheckBox>
      <CheckBox>installing, configuring, testing, maintaining and administering new and upgraded networks, software database applications, servers and workstations</CheckBox>
      <CheckBox>providing network programming in support of specific business needs and requirements</CheckBox>
      <CheckBox>
        preparing and maintaining procedures and documentation for network inventory, and recording diagnosis and resolution of network faults, enhancements and modifications to networks, and
        maintenance instructions
      </CheckBox>
      <CheckBox>monitoring network traffic, and activity, capacity and usage to ensure continued integrity and optimal network performance</CheckBox>
    </div>
  );
};

export default NetworkAnalyst;
