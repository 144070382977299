export interface OptionsType {
    label: string;
    competency: "Competent" | "Proficient" | "Superior";
    points?: number;
    year?: number;
}

export const o1: OptionsType[] = [
    {
        competency: "Superior",
        label: "At least 8 for each of the 4 test components",
        points: 20,
    },
    {
        competency: "Proficient",
        label: "At least 7 for each of the 4 test components",
        points: 10,
    },
    {
        competency: "Competent",
        label: "At least 6 for each of the 4 test components",
        points: 0,
    },
    {
        competency: "Competent",
        label: "Less than 6 for each of the 4 test components",
        points: 0,
    },
    {
        competency: "Competent",
        label: "Have not completed",
        points: 0,
    },
];
export const o2: OptionsType[] = [
    {
        competency: "Superior",

        label: "At least 28 for listening, 29 for reading , 30 for writing and 26 for speaking",
        points: 20,
    },
    {
        competency: "Proficient",
        label: "At least 24 for listening, 24 for reading , 27 for writing and 23 for speaking",
        points: 10,
    },
    {
        competency: "Competent",
        label: "At least 12 for listening, 13 for reading , 21 for writing and 18 for speaking",
        points: 0,
    },
    {
        competency: "Competent",
        label: "Less than 12 for listening, 13 for reading , 21 for writing and 18 for speaking",
        points: 0,
    },
    {
        competency: "Competent",
        label: "Have not completed",
        points: 0,
    },
];
export const o3: OptionsType[] = [
    {
        competency: "Superior",
        label: "At least 79 for each of the 4 test components",

        points: 20,
    },
    {
        competency: "Proficient",
        label: "At least 65 for each of the 4 test components",
        points: 10,
    },
    {
        competency: "Competent",
        label: "At least 50 for each of the 4 test components",
        points: 0,
    },
    {
        competency: "Competent",
        label: "Less than 50 for each of the 4 test components",
        points: 0,
    },
    {
        competency: "Competent",
        label: "Have not completed",
        points: 0,
    },
];
export const o4: OptionsType[] = [
    {
        competency: "Superior",
        label: "At least A for each of the 4 test components",
        points: 20,
    },
    {
        competency: "Proficient",
        label: "At least B for each of the 4 test components",
        points: 10,
    },
    {
        competency: "Competent",
        label: "Less than B for each of the 4 test components",
        points: 0,
    },
    {
        competency: "Competent",
        label: "Have not completed",
        points: 0,
    },
];
export const o5: OptionsType[] = [
    {
        competency: "Superior",

        label: "At least 200 in each of the 4 test components",
        points: 20,
    },
    {
        competency: "Proficient",
        label: "At least 185 in each of the 4 test components",
        points: 10,
    },
    {
        competency: "Competent",
        label: "At least 169 in each of the 4 test components",
        points: 0,
    },
    {
        competency: "Competent",
        label: "Less than 169 in each of the 4 test components",
        points: 0,
    },
    {
        competency: "Competent",
        label: "Have not completed",
        points: 0,
    },
];
