import {motion} from "framer-motion";
import {useEffect, useRef, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setDegree} from "../../feature/Modal/modalSlice";
import {activePage, pushHistory} from "../../feature/QSlice/QSlice";
import ArrowComponent from "../Custom/ArrowComponent";
import Title from "./Title";
const Q5 = () => {
    const yesRef = useRef<HTMLInputElement>(null);
    const noRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [points, setPoints] = useState(0);

    useEffect(() => {
        dispatch(pushHistory({id: 5, name: "q5"}));
        dispatch(activePage({id: 5, name: "q5"}));
    }, [dispatch]);

    const handleChange = () => {
        const yes = yesRef.current?.checked;

        if (yes) {
            setPoints(10);
        } else {
            setPoints(0);
        }
    };

    const handleNextPage = () => {
        const yes = yesRef.current?.checked;
        const no = noRef.current?.checked;

        if (!(yes || no)) alert("Please select either Yes or No");
        else {
            if (yes) {
                dispatch(setDegree("yes"));
            } else {
                dispatch(setDegree("no"));
            }
            navigate("/summary");
        }
    };
    return (
        <motion.div
            initial={{
                y: 500,
                x: 0,
                opacity: 0,
            }}
            animate={{
                y: 0,
                x: 0,
                opacity: 1,
            }}
            transition={{
                type: "tween",
                ease: "anticipate",
                duration: 1,
            }}
            exit={{
                y: 0,
                y1: 1000,
                opacity: 0,
            }}
            className='h-screen flex flex-col items-center sm:space-y-[40px]'>
            <Title questionNumber='5' title='Education' />
            <div className='flex mx-4 2xl:w-[1600px] justify-around items-center flex-col sm:mx-12 space-y-16 2xl:space-y-0 2xl:flex-row '>
                <div className=' sm:text-left 2xl:flex 2xl:flex-row'>
                    <span className='text-[#5A5A5A] sm:text-[30px] sm:w-[1350px] sm:leading-9  sm:mr-2'>
                        Have you completed a degree or diploma level
                        qualification in a course which is closely aligned with
                        your profession, or have you obtained a recognised trade
                        qualification closely aligned with your trade?
                    </span>
                    {/* <div className='inline-flex align-top 2xl:flex w-6 h-6 justify-center items-center border border-[#B08662] rounded-full'>
                        <FontAwesomeIcon
                            icon={faExclamation}
                            className='text-[#B08662]'
                        />
                    </div> */}
                </div>
                <motion.div className='flex flex-col justify-end space-y-[9px] items-center  sm:text-2xl text-[#5A5A5A]'>
                    <div className='flex items-center space-x-[13px]'>
                        <label htmlFor='yes'>Yes</label>
                        <input
                            type='radio'
                            id='yes'
                            name='eng_language'
                            value={1}
                            className='w-5 h-5 sm:w-[30px] sm:h-[30px] accent-[#B88E27]'
                            ref={yesRef}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='flex items-center space-x-[18px]'>
                        <label htmlFor='no'>No</label>
                        <input
                            type='radio'
                            id='no'
                            name='eng_language'
                            value={0}
                            className='w-5 h-5 sm:w-[30px] sm:h-[30px] accent-[#B88E27]'
                            ref={noRef}
                            onChange={handleChange}
                        />
                    </div>
                </motion.div>
            </div>
            <div onClick={handleNextPage}>
                <ArrowComponent />
            </div>
        </motion.div>
    );
};

export default Q5;
