import {DocumentData} from "firebase/firestore";
import moment from "moment";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {open} from "../../feature/Modal/modalSlice";
import {saveSelectedCard} from "../../feature/Portal/PortalSlice";
interface ListObjectProps {
    data: DocumentData;
    id: string;
}

interface CardProps {
    data: ListObjectProps;
}

const Card = ({data}: CardProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        dispatch(saveSelectedCard(data.id));
        dispatch(open({buttonType: "Close"}));
        navigate(`/portal/${data.id}`);
    };
    const {firstName, surname, userResponse} = data.data ?? {};

    const englangPoints =
        userResponse?.postAnswer?.currentPoints?.englang?.points ?? 0;
    const specPoints =
        userResponse?.postAnswer?.currentPoints?.specialisationPoints ?? 0;
    const educPoints = userResponse?.postAnswer?.currentPoints?.education ?? 0;

    //console.log({englangPoints, specPoints, educPoints});

    const totalPoints =
        userResponse?.points + englangPoints + specPoints + educPoints;
    let date = "";
    if (userResponse?.createAt) {
        //console.log(date.toDate());
        date = moment(userResponse.createAt.toDate()).format("D-MMM-YYYY");
    }

    return (
        <>
            <div
                className='p-3 shadow-lg'
                onClick={handleDoubleClick}
                id={data.id}>
                <div className='flex justify-between px-2 pb-2 font-bold '>
                    <div className='border-b-2 border-[#58061fe5]'>{`${firstName} ${surname}`}</div>
                    <div className='bg-gray-200 h-7 w-7 flex justify-center items-center rounded-full overflow-hidden text-xs'>
                        {firstName.charAt(0)}
                        {surname.charAt(0)}
                    </div>
                </div>
                <div className='flex justify-between px-2'>
                    <div>Questionnaire Date</div>
                    <div>{date}</div>
                </div>
                <div className='flex justify-between px-2'>
                    <div>Total Points:</div>
                    <div>{totalPoints}</div>
                </div>
                <div className='flex justify-between px-2 pb-2'>
                    <div>Country of Citizenship: </div>
                    <div>{userResponse?.countries[0]}</div>
                </div>
            </div>
            {/*  <ProfileModal /> */}
        </>
    );
};

export default Card;
