import emailjs from "@emailjs/browser";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import "yup-phone";
import { visibleSelectors } from "../../../feature/Modal/modalSelector";
import { close, open } from "../../../feature/Modal/modalSlice";
import { RootState } from "../../../feature/store";
import NavBar from "../../HomePage/NavBar";
import Modal from "../../Modal/Modal";
import FormikControl from "../../SignUp/FormikControl";
import Title from "../PageContainer/Title/Title";

const InvestmentPathway = () => {
  const visible = useSelector(visibleSelectors);
  const countries = useSelector((state: RootState) => state.modal.countries);
  const english = useSelector((state: RootState) => state.modal.english);
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID ?? "";
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY ?? "";

  const workExperience = useSelector((state: RootState) => state.modal.workExperience);
  const age = useSelector((state: RootState) => state.modal.age);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const initialValues = {
    firstname: "",
    surname: "",
    email: "",
    phone: "",
  };
  const validationSchema = Yup.object({
    firstname: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    surname: Yup.string().required("Required"),
    phone: Yup.string().required().phone("IN", false, "Please add '+' w/ country code"),
  });

  const onSubmit = async (values: any) => {
    //console.log(countries.join());

    setLoading(true);
    const newValues = {
      ...values,
      countries: countries.join(),
      competent: english ? "Competent" : "",
      workExperience,
      age,
    };

    /*  const message = ReactDOMServer.renderToStaticMarkup(
            <div>
                <ul>
                    {countries.map((country) => (
                        <li>{country}</li>
                    ))}
                </ul>
            </div>
        ); */
    if (process.env.REACT_APP_PROD === "true") {
      emailjs.send(serviceId, "template_e6vrlge", { ...newValues }, publicKey).then(
        async (result) => {
          await emailjs.send(serviceId, "template_t263fpq", newValues, publicKey);
          setLoading(false);
          dispatch(open({ buttonType: "Home Page", Q: "/" }));
        },
        (error) => {
          setLoading(false);
          alert(error.text);
        }
      );
    }
  };

  useEffect(() => {
    dispatch(close());
  }, [dispatch]);

  return (
    <div className="flex flex-col sm:space-y-16">
      <div className="mb-[20px] w-screen flex justify-center items-center">
        <NavBar />
      </div>
      <Title title={"Investment Pathway"} />
      {!visible && (
        <div className="flex justify-center items-center ">
          <div className="flex  flex-col justify-center items-center border border-[#B08662] bg-white p-18 ">
            <div className=" text-[#5A5A5A] sm:text-xl font-semibold sm:w-2/3 text-center pb-10">To access the Investment Pathway information, please provide your detail below</div>

            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              <Form className="flex flex-col justify-center items-center space-y-7">
                <div className="flex flex-col items-start space-y-2 sm:w-[550px]">
                  <FormikControl
                    control="pathway"
                    type="text"
                    name="firstname"
                    label="First Name"
                    placeholder="Enter your first name"
                  />
                  <FormikControl
                    control="pathway"
                    type="text"
                    name="surname"
                    label="Surname"
                    placeholder="Enter your surname"
                  />
                  <FormikControl
                    control="pathway"
                    type="email"
                    name="email"
                    label="Email Address"
                    placeholder="Enter your email address"
                  />
                  <FormikControl
                    control="pathway"
                    type="phone"
                    name="phone"
                    label={`Phone Number \n (including country code) `}
                    placeholder="Enter your phone number"
                  />
                </div>

                {!loading ? (
                  <button type="submit">
                    <motion.div
                      role={"presentation"}
                      whileHover={{
                        textShadow: "0px 0px 8px rgb(255,255,255)",
                        boxShadow: "0px 0px 8px rgb(255,255,255)",
                        borderRadius: "9999px",
                      }}
                      className="flex flex-row justify-center mb-2 items-center px-6 py-1   bg-[#58061E] opacity-100 text-white text-lg tracking-normal rounded-full cursor-pointer"
                    >
                      Submit
                    </motion.div>
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled
                  >
                    <motion.div
                      role={"presentation"}
                      whileHover={{
                        textShadow: "0px 0px 8px rgb(255,255,255)",
                        boxShadow: "0px 0px 8px rgb(255,255,255)",
                        borderRadius: "9999px",
                      }}
                      className="w-[305px] self-center flex mb-2 flex-row justify-center  items-center p-1   bg-[#58061E] opacity-80 text-white text-lg tracking-normal rounded-full"
                    >
                      <svg
                        className="animate-spin h-5 w-5 mr-3"
                        viewBox="0 0 24 24"
                      >
                        <FontAwesomeIcon icon={faSpinner} />
                      </svg>
                      Submit
                    </motion.div>
                  </button>
                )}
              </Form>
            </Formik>
          </div>
        </div>
      )}

      <Modal isOpen={visible}>
        <Scrollbars
          autoHide={false}
          className="w-[957px] h-[350px] text-2xl mx-[13px] my-0 overflow-hidden mt-[31px] text-[#707070]"
        >
          <div className="p-5 font-bold">Investment Pathway</div>
          <div className="leading-[29px] flex flex-col space-y-10 p-5">
            <span>Thank you for your interest in the Investment Pathway.</span>
            <span>One of our team will be on contact shortly to request further information.</span>
          </div>
        </Scrollbars>
      </Modal>
    </div>
  );
};

export default InvestmentPathway;
