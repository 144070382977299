import {createSelector} from "@reduxjs/toolkit";

import {RootState} from "../store";

export const IndustrySelector = createSelector(
    (state: Pick<RootState, "post">) => state.post.Industry,
    (data) => data
);
export const SpecialisationSelector = createSelector(
    (state: Pick<RootState, "post">) => state.post.Specialisation,
    (data) => data
);

export const EducationPointsSelector = createSelector(
    (state: Pick<RootState, "post">) => state.post.Education?.points,
    (data) => data
);

export const EducationSelector = createSelector(
    (state: Pick<RootState, "post">) => state.post.Education,
    (data) => data
);
export const DetailedSpecSelector = createSelector(
    (state: Pick<RootState, "post">) => state.post.DetailedSpec?.narrow,
    (data) => data
);

export const BroadSpecSelector = createSelector(
    (state: Pick<RootState, "post">) => state.post.DetailedSpec?.broad,
    (data) => data
);

export const ComputeEnglishScore = createSelector(
    (state: Pick<RootState, "post">) => state.post,
    (data) => {
        const {CAMBRIDGE, IELTSAcademic, IELTSGeneral, OET, PTE, TOEFL} = data;
        let arrayOfPoints = [];
        let points = 0;
        let proficiency = "Competent";

        for (const key of Object.keys(data)) {
            if (key === "CAMBRIDGE") {
                if (CAMBRIDGE && CAMBRIDGE.points)
                    arrayOfPoints.push(CAMBRIDGE.points);
            }
            if (key === "IELTSAcademic") {
                if (IELTSAcademic && IELTSAcademic.points)
                    arrayOfPoints.push(IELTSAcademic.points);
            }
            if (key === "IELTSGeneral") {
                if (IELTSGeneral && IELTSGeneral.points)
                    arrayOfPoints.push(IELTSGeneral.points);
            }
            if (key === "OET") {
                if (OET && OET.points) arrayOfPoints.push(OET.points);
            }
            if (key === "PTE") {
                if (PTE && PTE.points) arrayOfPoints.push(PTE.points);
            }
            if (key === "TOEFL") {
                if (TOEFL && TOEFL.points) arrayOfPoints.push(TOEFL.points);
            }
        }

        if (arrayOfPoints.length > 0) {
            points = Math.max(...arrayOfPoints);
            if (points === 20) proficiency = "Superior";
            if (points === 10) proficiency = "Proficient";
        }

        return {points, proficiency};
    }
);

export const ComputeEducationScore = createSelector(
    (state: Pick<RootState, "post">) => state.post.Education,
    (data) => {
        if (data?.points) return data?.points;
        else return 0;
    }
);

export const TotalPointsSelector = createSelector(
    (state: Pick<RootState, "post">) => state.post.currentPoints,
    (state: Pick<RootState, "auth">) => state.auth.response,
    (postLoginPts, prePoints) => {
        return (
            postLoginPts.education +
            postLoginPts.englang.points +
            prePoints?.agePoints +
            prePoints?.workExperiencePoints +
            postLoginPts.specialisationPoints
        );
    }
);

export const EnglishLangSelector = createSelector(
    (state: Pick<RootState, "post">) => state.post,
    (data) => data
);

export const SpecialisationSelectorPoints = createSelector(
    (state: Pick<RootState, "post">) => state.post.Specialisation,
    (data) => {
        if (data === "yes") return 10;
        return 0;
    }
);
