import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {saveEducation} from "../../../../feature/PostLogin/postLoginSlice";
import UseDropdownHook from "../../../Customhook/DropdownCustomhook";
import Dropdown from "../../../DropdownMenu/Dropdown/Dropdown";
import {Q2OptionsType} from "../../../Questions/type";

const options: Q2OptionsType[] = [
    {
        label: "PhD (Doctorate)",
        value: "PhD (Doctorate)",
        points: 20,
    },
    {
        label: "Bachelor Degree or higher",
        value: "Bachelor Degree or higher",
        points: 15,
    },
    {
        label: "Trade Qualification including Diploma or Trade Certificate acquired at an Australian educational institution Other award or educational qualification recognized by the relevant skills assessing authority",
        value: "Trade Qualification including Diploma or Trade Certificate acquired at an Australian educational institution Other award or educational qualification recognized by the relevant skills assessing authority",
        points: 10,
    },

    {
        label: "No Qualifications",
        value: "No Qualifications",
        points: 0,
    },
];

const Education = () => {
    const dropdownData = UseDropdownHook();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(saveEducation({}));
        if (dropdownData.value) {
            const data = options.find((d) => d.value === dropdownData.value);

            dispatch(saveEducation(data));
        }
    }, [dropdownData.value, dispatch]);

    return (
        <div className='flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:w-[1082] justify-center items-center sm:space-x-10 sm:pt-28 sm:pb-72'>
            <div className='text-lg text-center text-[#5A5A5A] sm:text-3xl'>
                Please detail your educational qualifications
            </div>
            <div className='pb-4 sm:pb-0'>
                <Dropdown {...dropdownData} options={options} setSize>
                    Select the most applicable answer
                </Dropdown>
            </div>
        </div>
    );
};

export default Education;
