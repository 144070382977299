import CustomDropdown from "../../../DropdownMenu/CustomDropdown";
interface OptionsType {
  label: string;
  competency: "Competent" | "Proficient" | "Superior";
}

interface EnglishAnswerPropsType {
  id?: string;
  value: null | string;
  options?: OptionsType[];
  setValue: (v: string) => void;
}

const EnglishAnswer = ({ id, value, options, setValue }: EnglishAnswerPropsType) => {
  return (
    <CustomDropdown
      options={options}
      value={value}
      setValue={setValue}
      id={id}
    />
  );
};

export default EnglishAnswer;
