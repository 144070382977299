import CheckBox from "../CheckBox";

const ICTProjectManager = () => {
  return (
    <div className="flex flex-col justify-center items-center self-center space-y-4 capitalize-first sm:w-2/3 max-w-screen-lg">
      <div className="sm:text-xl lg:text-3xl xl:text-4xl text-[#5A5A5A] text-center">Select the professional duties you can demonstrate that you regularly perform as an ICT Project Manager</div>

      <CheckBox>analyzing information needs and specifying technology to meet those needs</CheckBox>
      <CheckBox>formulating and directing information and communication technology (ICT) strategies, policies and plans</CheckBox>
      <CheckBox>directing the selection and installation of ICT resources and the provision of user training</CheckBox>
      <CheckBox>directing ICT operations and setting priorities between system developments, maintenance and operations</CheckBox>
      <CheckBox>overseeing the security of ICT systems</CheckBox>
    </div>
  );
};

export default ICTProjectManager;
