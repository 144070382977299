import {combineReducers} from "@reduxjs/toolkit";
import authReducer from "../feature/AuthSlice";
import modalReducer from "../feature/Modal/modalSlice";
import portalReducer from "../feature/Portal/PortalSlice";
import postReducer from "../feature/PostLogin/postLoginSlice";
import questionReducer from "../feature/QSlice/QSlice";

const reducers = {
    auth: authReducer,
    modal: modalReducer,
    question: questionReducer,
    post: postReducer,
    portal: portalReducer,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
