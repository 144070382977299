import aplLogo from "../../../../images/APL-black-600px.png";

const APLLogo = () => {
    return (
        <div className='flex justify-center items-center '>
            <img src={aplLogo} alt='' />
        </div>
    );
};

export default APLLogo;
