import classNames from "classnames";
import { ErrorMessage, Field, FieldAttributes } from "formik";
import TextError from "./TextError";

function Input(props: FieldAttributes<any>) {
  const { label, placeholder, name, ...rest } = props;
  return (
    <div
      className={classNames("", {
        "w-full sm:mr-3": !(name === "state" || name === "postcode"),
      })}
    >
      <div className="flex flex-col sm:flex-row justify-end items-center ">
        <label
          htmlFor={name}
          className="sm:text-xl"
        >
          {label}
        </label>
        <Field
          id={name}
          name={name}
          {...rest}
          className={classNames("border border-[#DED1C5] outline-none text-sm px-4 py-2.5 sm:pl-4 sm:text-lg sm:ml-5", {
            "sm:w-[350px] sm:ml-5": name !== "state" || name !== "postcode",
            "sm:w-[110px]": name === "state" || name === "postcode",
          })}
          placeholder={placeholder}
        />
      </div>

      <ErrorMessage
        component={TextError}
        name={name}
      />
    </div>
  );
}

export default Input;
