import {motion} from "framer-motion";

interface FooterPropTypes {
    onClick?: () => void;
}

const Footer = ({onClick}: FooterPropTypes) => {
    return (
        <motion.div
            whileHover={{
                scale: 1.1,
                textShadow: "0px 0px 8px rgb(88,6,30)",
                boxShadow: "0px 0px 8px rgb(88,6,30)",
                borderRadius: "6px",
            }}
            onClick={onClick}
            className='  flex justify-center items-center cursor-pointer'>
            <img
                src='/images/arrowhex.png'
                alt='hexagon'
                className='w-16 sm:w-24'
            />
        </motion.div>
    );
};

export default Footer;
