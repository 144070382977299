import {Field, FieldAttributes} from "formik";

function InputProfile(props: FieldAttributes<any>) {
    const {label, placeholder, name, ...rest} = props;
    return (
        <div>
            <div className='flex flex-col space-y-1 sm:space-y-0 sm:flex-row items-center justify-center'>
                <label
                    htmlFor={name}
                    className='text-lg sm:text-xl sm:w-1/3 text-[#5A5A5A]'>
                    {label}
                </label>
                <Field
                    id={name}
                    name={name}
                    {...rest}
                    className='text-base p-1 border border-[#DED1C5] bg-white outline-none sm:w-[350px] sm:py-2.5 sm:pl-4 sm:text-lg sm:ml-5 text-[#A09FA1] truncate'
                    placeholder={placeholder}
                />
            </div>

            {/*  <ErrorMessage component={TextError} name={name} /> */}
        </div>
    );
}

export default InputProfile;
