import {motion} from "framer-motion";
import {useEffect} from "react";
import Scrollbars from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";
import {useDispatch, useSelector} from "react-redux";
import {visibleSelectors} from "../../feature/Modal/modalSelector";
import {activePage, pushHistory} from "../../feature/QSlice/QSlice";
import NavBar from "../HomePage/NavBar";
import Modal from "../Modal/Modal";
import SignUpForm from "./SignUpForm";
const SignUpContainer = () => {
    const visible = useSelector(visibleSelectors);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pushHistory({id: 7, name: "signup"}));
        dispatch(activePage({id: 7, name: "signup"}));
    }, [dispatch]);
    return (
        <motion.div
            initial={{
                y: 500,
                x: 0,
                opacity: 0,
            }}
            animate={{
                y: 0,
                x: 0,
                opacity: 1,
            }}
            transition={{
                type: "tween",
                ease: "anticipate",
                duration: 1,
            }}
            exit={{
                y: 0,
                y1: 1000,
                opacity: 0,
            }}
            className='relative h-screen flex flex-col items-center space-y-[5px] sm:space-y-[40px]'>
            <div className='mb-[20px] w-screen flex justify-center items-center'>
                <NavBar />
            </div>
            <div className='text-3xl sm:text-[57px] text-[#58061E]  leading-[68px]'>
                Create an Account
            </div>

            <div className='px-2 sm:px-0 lg:w-[848px]  flex flex-col justify-center items-center sm:text-[30px] sm:leading-[36px] text-center text-[#5A5A5A]'>
                {!visible && (
                    <span>
                        To continue assisting you with your Skilled Migrant Visa
                        process, we require you to create an account.
                    </span>
                )}
            </div>
            <div className='relative border border-[#B08662] lg:w-[713px]  p-3'>
                <div>{!visible && <SignUpForm />}</div>
            </div>

            <div>
                <Modal isOpen={visible}>
                    <Scrollbars
                        autoHide={false}
                        className='overscroll-x-none w-[957px] h-[350px] sm:text-2xl mx-[13px] my-0 overflow-hidden mt-[31px] text-[#707070]'>
                        <div className='p-2 font-bold'>Thank you</div>
                        <div className='leading-[29px] flex flex-col space-y-10 p-2'>
                            <span>
                                Thank you for signing up. You'll receive a
                                confirmation email shortly
                            </span>
                        </div>
                    </Scrollbars>
                </Modal>
            </div>
        </motion.div>
    );
};

export default SignUpContainer;
