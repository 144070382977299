import {ErrorMessage, Field, FieldAttributes} from "formik";
import TextError from "./TextError";

function SigninInput(props: FieldAttributes<any>) {
    const {label, placeholder, name, className, horizontalErr, ...rest} = props;
    return (
        <div>
            <Field
                id={name}
                name={name}
                {...rest}
                className='text-sm sm:text-base sm:w-[305px] border border-[#DED1C5] outline-none py-1 pl-2 rounded-full'
                placeholder={placeholder}
            />

            <ErrorMessage component={TextError} name={name} />
        </div>
    );
}

export default SigninInput;
