import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {reset} from "../feature/Modal/modalSlice";
import {QuestionSelector} from "../feature/QSlice/QSliceSelector";

const ProtectedRouteStep = ({
    children,
}: {
    children: React.ReactElement;
}): JSX.Element => {
    const navigate = useNavigate();
    const {page} = useParams();
    const dispatch = useDispatch();

    const {currentQuestion, navigatedQuestion} =
        useSelector(QuestionSelector);

    let name = "";

    if (page) name = page;

    const nextPage = navigatedQuestion.find((q) => q.name === name);

    if (
        nextPage &&
        nextPage.id < currentQuestion.id &&
        navigatedQuestion.length > 1
    ) {
        dispatch(reset());
        navigate("/");
    }

    return children;
};

export default ProtectedRouteStep;
