import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addDoc, collection } from "firebase/firestore";
import { Form, Formik } from "formik";
import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "yup-phone";
import { SaveResponseSelector } from "../../feature/Modal/modalSelector";
import { open, reset } from "../../feature/Modal/modalSlice";
import { db, Register } from "../../firebase";
import Error from "../ErrorComponent/Error";
import FormikControl from "./FormikControl";

const SignUpForm = () => {
  const dispatch = useDispatch();
  const response = useSelector(SaveResponseSelector);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    firstName: "",
    surname: "",
    phone: "",
    password: "",
    confirmPass: "",
    address: "",
    postcode: "",
    state: "",
    city: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().required("Required"),
    firstName: Yup.string().required("Required"),
    surname: Yup.string().required("Required"),
    phone: Yup.string()
      .required()
      .phone("IN", false, "Please add '+' w/ country code"),
    password: Yup.string().required("Required"),
    confirmPass: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Passwords must match")
      .required("Required"),
    address: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    postcode: Yup.string().required("Required"),
  });
  const onSubmit = async (values: any) => {
    setLoading(true);
    const {
      email,
      password,
      phone,
      firstName,
      surname,
      address,
      postcode,
      city,
      state,
    } = values;

    await Register(
      email,
      password,
      phone,
      firstName,
      surname,
      address,
      postcode,
      city,
      state
    ).then(async (v: any) => {
      if (!v?.fail) {
        await addDoc(collection(db, "UserResponse"), {
          uid: v.id,
          countries: response?.countries,
          english: response.english,
          workExperience: response.workExperience,
          degree: response.degree,
          points: response.points,
          age: response.age,
          agePoints: response.agePoints,
          degreePoints: response.degreePoints,
          workExperiencePoints: response.workExperiencePoints,
          createAt: new Date(),
          dateOfSubmission: null,
          stage: "Incomplete",
        })
          .then((v) => {
            setLoading(false);
            dispatch(reset());
            dispatch(open({ buttonType: "registered", Q: "/" }));
            navigate("/thankyou");
          })
          .catch((err: any) => {
            setLoading(false);
            alert(err);

            return false;
          });
      } else {
        setError(v.message);
        setLoading(false);
      }
    });
  };
  return (
    <>
      <Error clearError={setError} open={error !== ""}>
        {error === "email-already-in-use" ? (
          <p className="text-justify">
            The email address you have entered is already in use. You'll need to
            use a different email address. Alternatively, you may reset your
            password by clicking
            <a
              href="/forgotpassword"
              className="pl-1 underline font-extrabold text-blue-700"
            >
              here
            </a>
          </p>
        ) : (
          error
        )}
      </Error>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(formik: any) => {
          return (
            <Form className=" flex flex-col items-center space-y-3 sm:space-y-5 sm:mt-4">
              <FormikControl
                control="input"
                type="text"
                label="First Name"
                name="firstName"
                placeholder="Enter your first name"
              />
              <FormikControl
                control="input"
                type="text"
                label="Surname"
                name="surname"
                placeholder="Enter your surname"
              />
              <FormikControl
                control="input"
                type="tel"
                label="Phone Number with Country Code"
                name="phone"
                placeholder="Enter your phone number"
              />
              <FormikControl
                control="input"
                type="text"
                label="Address"
                name="address"
                placeholder="Enter your address"
              />
              <FormikControl
                control="input"
                type="text"
                label="City"
                name="city"
                placeholder="Enter your town or city"
              />
              <div className="w-full sm:mr-3 sm:flex sm:justify-end sm:space-x-8">
                <FormikControl
                  control="input"
                  type="text"
                  label="State"
                  name="state"
                  placeholder="State"
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Postcode"
                  name="postcode"
                  placeholder="Postcode"
                />
              </div>
              <FormikControl
                control="input"
                type="email"
                label="Email Address"
                name="email"
                placeholder="Enter your email address"
              />
              <FormikControl
                control="input"
                type="password"
                label="Create a Password"
                name="password"
                placeholder="Create Password"
              />
              <FormikControl
                control="input"
                type="password"
                label="Confirm a Password"
                name="confirmPass"
                placeholder="Confirm Password"
              />
              {!loading ? (
                <button type="submit">
                  <motion.div
                    role={"presentation"}
                    whileHover={{
                      scale: 1.1,
                      textShadow: "0px 0px 8px rgb(255,255,255)",
                      boxShadow: "0px 0px 8px rgb(255,255,255)",
                      borderRadius: "9999px",
                    }}
                    className="flex flex-row justify-center font-se items-center p-1  w-[130px] bg-[#58061E] opacity-100 text-white text-lg tracking-normal rounded-full cursor-pointer"
                  >
                    Submit
                  </motion.div>
                </button>
              ) : (
                <button type="submit" disabled>
                  <motion.div
                    role={"presentation"}
                    whileHover={{
                      textShadow: "0px 0px 8px rgb(255,255,255)",
                      boxShadow: "0px 0px 8px rgb(255,255,255)",
                      borderRadius: "9999px",
                    }}
                    className="flex flex-row justify-center font-se items-center p-1  w-[130px] bg-[#58061E] opacity-80 text-white text-lg tracking-normal rounded-full cursor-pointer"
                  >
                    <svg
                      className="animate-spin h-5 w-5 mr-3"
                      viewBox="0 0 24 24"
                    >
                      <FontAwesomeIcon icon={faSpinner} />
                    </svg>
                    Submit
                  </motion.div>
                </button>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SignUpForm;
