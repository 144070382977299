import {faQuestion} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {motion} from "framer-motion";
import {useEffect, useState} from "react";
import Scrollbars from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {
    buttonModalSelector,
    visibleSelectors,
} from "../../feature/Modal/modalSelector";
import {close, open, setWorkExperience} from "../../feature/Modal/modalSlice";
import {activePage, pushHistory} from "../../feature/QSlice/QSlice";
import ArrowComponent from "../Custom/ArrowComponent";
import UseDropdownHook from "../Customhook/DropdownCustomhook";
import Dropdown from "../DropdownMenu/Dropdown/Dropdown";
import Modal from "../Modal/Modal";
import Title from "./Title";

const options = [
    {
        count: 1,
        value: "1 year or less",
        label: "1 year or less",
    },
    {
        count: 2,
        value: "2 years",
        label: "2 years",
    },
    {
        count: 3,
        value: "3 years",
        label: "3 years",
    },
    {
        count: 4,
        value: "4 years",
        label: "4 years",
    },
    {
        count: 5,
        value: "5 years",
        label: "5 years",
    },
    {
        count: 6,
        value: "6 years",
        label: "6 years",
    },
    {
        count: 7,
        value: "7 years",
        label: "7 years",
    },
    {
        count: 8,
        value: "8 years",
        label: "8 years",
    },
    {
        count: 9,
        value: "9 years",
        label: "9 years",
    },
    {
        count: 10,
        value: "10 years or more",
        label: "10 years or more",
    },
];

const Q4 = () => {
    const dropdownData = UseDropdownHook();
    const dispatch = useDispatch();
    const visible = useSelector(visibleSelectors);
    const navigate = useNavigate();
    const openInfoModal = () => {
        dispatch(open({buttonType: "Close"}));
    };
    const {buttonType} = useSelector(buttonModalSelector);

    const [points, setPoints] = useState(0);

    useEffect(() => {
        dispatch(close());
        dispatch(pushHistory({id: 4, name: "q4"}));
        dispatch(activePage({id: 4, name: "q4"}));
    }, [dispatch]);

    useEffect(() => {
        if (dropdownData.value) {
            const payload = options?.find(
                (v) => v.value === dropdownData.value
            );
            if (payload) {
                if (payload.count < 3) setPoints(0);
                else if (payload.count >= 3 && payload.count < 5) setPoints(5);
                else if (payload.count >= 5 && payload.count < 8) setPoints(10);
                else if (payload.count >= 8) setPoints(15);
            }
        }
    }, [dropdownData.value]);

    const handleNextPage = () => {
        if (!dropdownData.value) alert("Please select most applicable");
        else {
            const payload = options?.find(
                (v) => v.value === dropdownData.value
            );
            if (payload) {
                if (payload?.count < 3) {
                    dispatch(setWorkExperience(payload?.count));
                    dispatch(open({buttonType: "Home Page", Q: "/"}));
                } else {
                    //dispatch(open({buttonType: "Proceed", Q: "/q5"}));

                    const result = options.find(
                        (v) => v.value === dropdownData.value
                    );
                    if (result) {
                        dispatch(setWorkExperience(result?.count)); //save number of experience
                        navigate("/q5");
                    }
                }
            }
        }
    };

    return (
        <motion.div
            initial={{
                y: 500,
                x: 0,
                opacity: 0,
            }}
            animate={{
                y: 0,
                x: 0,
                opacity: 1,
            }}
            transition={{
                type: "tween",
                ease: "anticipate",
                duration: 1,
            }}
            exit={{
                y: 0,
                y1: 1000,
                opacity: 0,
            }}
            className='h-screen flex flex-col items-center  space-y-[5px] sm:space-y-[40px]'>
            <Title questionNumber='4' title='Work Experience' />
            <div className='flex  2xl:w-[1600px] justify-around items-center flex-col mx-12 space-y-16 2xl:space-y-0 2xl:flex-row '>
                <div className='text-center 2xl:flex 2xl:flex-row'>
                    <span className='text-lg text-[#5A5A5A] sm:text-[27px]  sm:pr-1'>
                        How many years of employed experience do you have in
                        your profession or trade?
                    </span>
                    <motion.div
                        whileHover={{
                            scale: 1.1,
                            textShadow: "0px 0px 2px #B08662",
                            boxShadow: "0px 0px 5px #B08662",
                            borderRadius: "9999px",
                        }}
                        onClick={openInfoModal}
                        className='inline-flex align-top 2xl:flex w-4 h-4 sm:w-6 sm:h-6 justify-center items-center border border-[#B08662] rounded-full'>
                        <FontAwesomeIcon
                            icon={faQuestion}
                            className='text-[#B08662] w-3 h-3'
                        />
                    </motion.div>
                </div>

                <Dropdown {...dropdownData} options={options}>
                    Select the most applicable
                </Dropdown>
            </div>
            <div onClick={handleNextPage}>
                <ArrowComponent />
            </div>
            <div>
                <Modal isOpen={visible}>
                    <Scrollbars
                        autoHide={false}
                        className='w-[957px] h-[350px] sm:text-2xl mx-[13px] my-0 overflow-hidden mt-[31px] text-[#707070]'>
                        {buttonType !== "Home Page" ? (
                            <>
                                <div className='p-2 font-extrabold'>
                                    Professional Experience
                                </div>
                                <div className='leading-[29px] flex flex-col space-y-10 p-2'>
                                    <span>
                                        The Australian Government will assess
                                        relevant employed experience in the most
                                        recent ten years, at the time of
                                        application
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='p-2 font-bold'>
                                    Professional Experience Minimum Requirements
                                    not met
                                </div>
                                <div className='leading-[29px] flex flex-col space-y-10 p-2'>
                                    <span>
                                        Applicants must have a minimum of two
                                        years' employed experience in order to
                                        be considered for independent skilled
                                        migration. Unfortunately your answer
                                        indicates that you are currently
                                        ineligible.
                                    </span>
                                    <span>
                                        If you have significant wealth, or own a
                                        highly successful business, you may be
                                        eligible to migrate to Australia under a
                                        business, innovation or investment
                                        pathway. If you would like to understand
                                        your eligibility for a business,
                                        innovation or investment visa, please
                                        click
                                        <Link to='/investmentpathway'>
                                            <span className='text-[#0316F8] font-semibold'>
                                                {" here."}
                                            </span>
                                        </Link>
                                    </span>
                                </div>
                            </>
                        )}
                    </Scrollbars>
                </Modal>
            </div>
        </motion.div>
    );
};

export default Q4;
