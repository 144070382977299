import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {motion} from "framer-motion";
import React from "react";

interface ButtonFormProps {
    text: string;
    loading?: boolean;
    onClick?: () => void;
    ref?: React.ForwardedRef<HTMLButtonElement>;
}

const ButtonForm = ({text, loading, onClick, ref}: ButtonFormProps) => {
    return (
        <button type='submit' ref={ref}>
            <motion.div
                role={"presentation"}
                whileHover={{
                    textShadow: "0px 0px 8px rgb(255,255,255)",
                    boxShadow: "0px 0px 8px rgb(255,255,255)",
                    borderRadius: "9999px",
                }}
                className={classNames(
                    " m-1 py-1  sm:w-[305px] flex flex-row justify-center  items-center bg-[#58061E] opacity-100 text-white sm:text-lg tracking-normal rounded-full cursor-pointer",
                    {
                        "opacity-100 cursor-pointer px-14": !loading,
                        "opacity-80 px-8": loading,
                    }
                )}
                onClick={onClick}>
                {loading && (
                    <svg
                        className='animate-spin h-5 w-5 mr-3'
                        viewBox='0 0 24 24'>
                        <FontAwesomeIcon icon={faSpinner} />
                    </svg>
                )}
                {loading ? "Loading..." : text}
            </motion.div>
        </button>
    );
};

export default ButtonForm;
