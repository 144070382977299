import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectedCountrySelector} from "../../feature/Modal/modalSelector";
import {setCountries} from "../../feature/Modal/modalSlice";

import "./DropdownCountries.css";

interface DropdownCountriesProps {
    selectRef: React.RefObject<HTMLSelectElement>;
    options: string[];
    id: string;
    value?: string;
    loading?: boolean;
}

const DropdownCountries = ({
    options,
    selectRef,
    id,
    value,
    loading,
}: DropdownCountriesProps) => {
    const selecteCountry = useSelector(selectedCountrySelector);
    const ref = useRef<HTMLSelectElement>(null);
    const dispatch = useDispatch();
    const [country, setCountry] = useState(value);
    const [newOptions, setNewOptions] = useState<string[]>([]);

    useEffect(() => {
        setCountry("Select Country");
    }, [value]);

    useEffect(() => {
        //console.log(selecteCountry);
        /*  if (selecteCountry.length > 0) {
            let newOptions = options.filter(
                (country: string) => !selecteCountry.includes(country)
            );
            setNewOptions(newOptions);

            console.log(ref.current?.value);
            setCountry(ref.current?.value);
            //console.log(d);
        } */
    }, []);

    const handleChange = (e: React.ChangeEvent) => {
        e.stopPropagation();
        setCountry(ref.current?.value);
        switch (id) {
            case "0":
                dispatch(setCountries({id: 0, country: ref.current?.value}));
                break;
            case "1":
                dispatch(setCountries({id: 1, country: ref.current?.value}));
                break;
            case "2":
                dispatch(setCountries({id: 2, country: ref.current?.value}));
                break;

            case "3":
                dispatch(setCountries({id: 3, country: ref.current?.value}));
                break;
        }
    };
    return (
        <>
            <div className='relative w-[227px] h-7 border border-solid border-[#DED1C5] sm:w-[305px] sm:h-[45px] bg-white '>
                <select
                    id={id}
                    className='     cursor-pointer
                                appearance-none
                                sm:text-xl
                                rounded
                                outline-none
                                z-10
                                w-[220px]
                                h-6
                                sm:w-[305px]
                                sm:h-[45px]
                                text-[#A09FA1]
                                absolute
                                pl-[10px]
                                bg-transparent
                                
                                '
                    ref={ref}
                    value={country}
                    defaultValue={"Select Country"}
                    onChange={(e) => handleChange(e)}>
                    {loading ? (
                        <option disabled value={"Select Country"}>
                            Loading options......
                        </option>
                    ) : (
                        <>
                            <option disabled value={"Select Country"}>
                                Select Country
                            </option>

                            {options?.map((o, i) => (
                                <option
                                    key={i}
                                    value={o}
                                    className='text-[#707070] odd:bg-[#FCFAF9] border border-t-[#E6DBD0] truncate'>
                                    {o}
                                </option>
                            ))}
                        </>
                    )}
                </select>
                <div className='absolute  h-7 w-7 right-0 -top-0 flex justify-center items-center bg-[#B08662] m-0 p-0 sm:h-[45px] sm:w-[45px] cursor-pointer'>
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        className={"text-white"}
                    />
                </div>
            </div>
        </>
    );
};

export default DropdownCountries;
