import {memo} from "react";
import {Draggable} from "react-beautiful-dnd";
import Card from "./Card";

interface DndListItemProps {
    item: any;
    index: any;
}

const DndListItem = ({item, index}: DndListItemProps) => {
    return (
        <>
            <Draggable draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                    <div
                        className='bg-white'
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}>
                        <Card data={item} key={item.id} />
                    </div>
                )}
            </Draggable>
        </>
    );
};

export default memo(DndListItem);
