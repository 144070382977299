import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { visibleSelectors } from "../../../../feature/Modal/modalSelector";
import { close, open } from "../../../../feature/Modal/modalSlice";
import { saveIndustry } from "../../../../feature/PostLogin/postLoginSlice";
import Modal from "../../../Modal/Modal";
import ModalContent from "./ModalContent";

export enum IndustryOption {
  Information = "Information Technology",
  Others = "Others",
}

const Industry = () => {
  const isOpen = useSelector(visibleSelectors);
  const dispatch = useDispatch();

  const yesRef = useRef<HTMLInputElement>(null);
  const noRef = useRef<HTMLInputElement>(null);

  const _handleMouseEnter = () => {
    dispatch(open({ buttonType: "Close" }));
  };

  const _handleChange = () => {
    const yes = yesRef.current;
    const no = noRef.current;

    if (yes?.checked) dispatch(saveIndustry(yes.value));
    else if (no?.checked) dispatch(saveIndustry(no.value));
  };

  useEffect(() => {
    dispatch(close());
  }, [dispatch]);

  return (
    <>
      <div className="sm:text-xl lg:text-3xl xl:text-4xl px-10 sm:px-0 text-center text-[#5A5A5A]">Please select the industry that you’re applying for</div>
      <div className="flex items-center  justify-center py-4 lg:pb-60 lg:pt-20">
        <div className="flex flex-col items-center justify-center pr-2 text-[hsl(0,0%,35%)] sm:pr-44 ">
          <div className="flex">
            <span className="text-sm lg:items-end sm:text-lg lg:text-2xl xl:text-3xl sm:leading-9 sm:mr-2">Information Technology</span>
            <motion.div
              whileHover={{
                scale: 1.1,
                textShadow: "0px 0px 2px #B08662",
                boxShadow: "0px 0px 5px #B08662",
                borderRadius: "9999px",
              }}
              onClick={_handleMouseEnter}
              className="w-4 h-4 inline-flex align-top 2xl:flex sm:w-6 sm:h-6 justify-center items-center border border-[#B08662] rounded-full"
            >
              <FontAwesomeIcon
                icon={faQuestion}
                className="w-3 h-3 text-[#B08662]"
              />
            </motion.div>
          </div>
          <div className="text-sm lg:items-end sm:text-lg lg:text-2xl xl:text-3xl sm:leading-9 sm:mr-2">Other Industry</div>
        </div>

        <div className="flex flex-col  space-y-[9px]  sm:text-2xl text-[#5A5A5A]">
          <div className="flex items-center sm:space-x-[13px]">
            <input
              id="yes"
              value={IndustryOption.Information}
              type="radio"
              ref={yesRef}
              name="eng_language"
              className="sm:w-[30px] sm:h-[30px] accent-[#B88E27]"
              onChange={_handleChange}
            />
          </div>
          <div className="flex items-center sm:space-x-[18px]">
            <input
              id="no"
              value={IndustryOption.Others}
              type="radio"
              ref={noRef}
              name="eng_language"
              className="sm:w-[30px] sm:h-[30px] accent-[#B88E27]"
              onChange={_handleChange}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        closeOnMouseLeave
      >
        <Scrollbars>
          <ModalContent />
        </Scrollbars>
      </Modal>
    </>
  );
};

export default Industry;
