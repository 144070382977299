import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ProfessionDutiesType {
    profession: string;
    duties: Array<string>;
}

interface initialStateType {
    show: boolean;
    buttonType: string;
    question: string;
    age: number;
    countries: string[];
    english: boolean;
    degree: boolean;
    points: number;
    workExperience: number;
    numOfCountries: number;
    selectedProfession: ProfessionDutiesType[];
    agePoints: number;
    workExperiencePoints: number;
    degreePoints: number;
}

const initialState: initialStateType = {
    show: false,
    buttonType: "",
    question: "",
    age: 0,
    countries: [],
    numOfCountries: 0,
    english: false,
    degree: false,
    points: 0,
    workExperience: 0,
    selectedProfession: [],
    agePoints: 0,
    workExperiencePoints: 0,
    degreePoints: 0,
};

const ModalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        close: (state: initialStateType) => {
            state.show = false;
        },
        open: (state: initialStateType, {payload}: PayloadAction<any>) => {
            //console.log(payload);
            state.show = true;
            state.buttonType = payload?.buttonType;
            state.question = payload?.Q;
        },
        saveAge: (state: initialStateType, {payload}: PayloadAction<any>) => {
            if (payload >= 18 && payload < 25) {
                state.points += 25;
                state.agePoints = 25;
            } else if (payload >= 25 && payload < 33) {
                state.points += 30;
                state.agePoints = 30;
            } else if (payload >= 33 && payload < 40) {
                state.points += 25;
                state.agePoints = 25;
            } else if (payload >= 40 && payload < 45) {
                state.points += 15;
                state.agePoints = 15;
            }
            state.age = payload;
        },
        setEnglish: (state: initialStateType) => {
            state.english = true;
        },
        setDegree: (state: initialStateType, {payload}: PayloadAction<any>) => {
            switch (payload) {
                case "yes":
                    state.points += 10;
                    state.degree = true;
                    state.degreePoints = 10;
                    break;
                case "no":
                    state.points += 0;
                    state.degree = false;
                    state.degreePoints = 0;
                    break;
            }
        },
        setCountries: (
            state: initialStateType,
            {payload}: PayloadAction<any>
        ) => {
            state.countries[payload.id] = payload.country;
        },
        setWorkExperience: (
            state: initialStateType,
            {payload}: PayloadAction<any>
        ) => {
            if (payload < 3) state.points += 0;
            else if (payload >= 3 && payload < 5) {
                state.points += 5;
                state.workExperiencePoints = 5;
            } else if (payload >= 5 && payload < 8) {
                state.points += 10;
                state.workExperiencePoints = 10;
            } else if (payload >= 8) {
                state.points += 15;
                state.workExperiencePoints = 15;
            }
            state.workExperience = payload;
        },
        saveNumOfCountries: (
            state: initialStateType,
            {payload}: PayloadAction<any>
        ) => {
            state.countries = [state.countries[0]];
            state.numOfCountries = payload;
        },
        reset: (state: initialStateType) => {
            state = initialState;
            return state;
        },
        addProfession: (state: {selectedProfession: any[]}, {payload}: any) => {
            const newPayload = {...payload, duties: []};
            state.selectedProfession.push(newPayload);
        },
        addDuties: (state: initialStateType, {payload}: any) => {
            //console.log(payload);
            const {duty, id} = payload;
            const intID = +id;

            state.selectedProfession[intID].duties.push(duty);
        },
        removeDuties: (state: initialStateType, {payload}: any) => {
            //console.log(payload);
            const {duty, id} = payload;
            const intID = +id;

            state.selectedProfession[intID].duties = state.selectedProfession[
                intID
            ].duties.filter((d) => d !== duty);
        },
        removeProfession: (
            state: {selectedProfession: any[]},
            {payload}: any
        ) => {
            state.selectedProfession = state.selectedProfession.filter(
                (d: ProfessionDutiesType) => d.profession !== payload.profession
            );
        },
    },
});

export const {
    close,
    open,
    saveAge,
    setEnglish,
    setDegree,
    setCountries,
    setWorkExperience,
    saveNumOfCountries,
    reset,
    removeProfession,
    addProfession,
    addDuties,
    removeDuties,
} = ModalSlice.actions;
export default ModalSlice.reducer;
