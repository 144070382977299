import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';

import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';

//prod
/* export const firebaseConfig = {
  apiKey: "AIzaSyBkwXCQx9R3afH5YHANq-9FZDXgYDcBcYM",
  authDomain: "apl-immigration-portal.firebaseapp.com",
  projectId: "apl-immigration-portal",
  storageBucket: "apl-immigration-portal.appspot.com",
  messagingSenderId: "492050877236",
  appId: "1:492050877236:web:1c023fdd0128cacb9cf361",
  measurementId: "G-BC07PKD2QB",
}; */

if (process.env.REACT_APP_PROD === 'true') console.log('production');
else if (process.env.REACT_APP_PROD === 'false') console.log('development');
else console.log('Error');

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_DOMAIN,
  projectId: process.env.REACT_APP_ID,
  storageBucket: process.env.REACT_APP_STORAGE,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT,
};

//dev

/* export const firebaseConfigDev = {
  apiKey: "AIzaSyDmIWRDtuGuocdWdcofHVwVuBFIOrOs4l4",
  authDomain: "apl-immigration-portal-dev.firebaseapp.com",
  projectId: "apl-immigration-portal-dev",
  storageBucket: "apl-immigration-portal-dev.appspot.com",
  messagingSenderId: "884577962670",
  appId: "1:884577962670:web:4c6c00eb6e044b85ed7b3f",
  measurementId: "G-6CYKVFM8F3",
}; */

const app = initializeApp(firebaseConfig); //switch to dev
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export default app;
//export const db = getFirestore(app);

export const db = initializeFirestore(app, { ignoreUndefinedProperties: true });

const redirectURI = process.env.REACT_APP_REDIRECT;
/* const redirectURI_DEV = "https://apl-immigration-portal-dev.web.app"; */

/* console.log({ redirectURI, firebaseConfig }); */

function logOut() {
  const message = signOut(auth);
}

const forgotPassword = async (email: string) => {
  return sendPasswordResetEmail(auth, email, {
    url: `${redirectURI}/signin`,
  });
};

async function signIn(email: string, password: string) {
  const userCredential = await signInWithEmailAndPassword(
    auth,
    email,
    password
  ).catch((err) => {
    alert(err);
  });

  return userCredential;
}

async function getUserCredentials(id: string) {
  const user = collection(db, 'users');
  const response = collection(db, 'UserResponse');

  const q = query(user, where('uid', '==', id));
  const q1 = query(response, where('uid', '==', id));

  const querySnapshotUser = await getDocs(q);
  const querySnapshotResponse = await getDocs(q1);
  console.log(querySnapshotResponse);
  return { querySnapshotUser, querySnapshotResponse };
}

async function Register(
  email: string,
  password: string,
  phone: string,
  firstName: string,
  surname: string,
  address: string,
  postcode: string,
  city: string,
  state: string
) {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);

    const user = res.user;

    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      firstName,
      surname,
      email,
      phone,
      address,
      postcode,
      city,
      state,
      authProvider: 'local',
    }).catch((err) => {
      alert(err);
      return { fail: true, message: err.message };
    });

    await sendEmailVerification(res.user, {
      url: `${redirectURI}/signin`,
    }).catch((err) => {
      alert(err);
      return { fail: false, message: err.message };
      //use isEmailVerified to confirm if it is verified
    });

    return { id: user.uid, fail: false };
  } catch (err: any) {
    //alert(err);
    if (err.code === 'auth/email-already-in-use') {
      return {
        fail: true,
        message: 'email-already-in-use',
      };
    }
    return { fail: true, message: err.message };
  }
}

export { Register, signIn, getUserCredentials, logOut, forgotPassword };
