import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {ProfessionSelector} from "../../../../feature/Modal/modalSelector";
import AsAnalystProgrammer from "./AsAnalystProgrammer";
import AsSystemAnalyst from "./AsSystemAnalyst";
import ComputerNetAndSysEngineer from "./ComputerNetAndSysEngineer";
import DBAdmin from "./DBAdmin";
import Developer from "./Developer";
import ICTBusinessAnalyst from "./ICTBusinessAnalyst";
import ICTProjectManager from "./ICTProjectManager";
import ICTSecSpecialist from "./ICTSecSpecialist";
import ICTSupportEngineer from "./ICTSupportEngineer";
import MultimediaSpecialist from "./MultimediaSpecialist";
import NetworkAnalyst from "./NetworkAnalyst";
import SoftwareEngineer from "./SoftwareEngineer";

export enum Profession {
    AnalystProgrammer = "Analyst Programmer",
    SystemAnalyst = "Systems Analyst",
    DatabaseAdmin = "Database Administrator",
    ComputerNetAndSysEngineer = "Computer Network and Systems Engineer",
    Developer = "Developer Programmer",
    BusinessAnalyst = "ICT Business Analyst",
    ProjectManager = "ICT Project Manager",
    SecuritySpecialist = "ICT Security Specialist",
    SupportEngineer = "ICT Support Engineer",
    MultimediaSpecialist = "Multimedia Specialist",
    NetworkAnalyst = "Network Analyst",
    SE = "Software Engineer",
}

const Duties = () => {
    const param = useParams();
    const id = param.dutiesID;
    const num = id ? +id : null;
    const profession = useSelector(ProfessionSelector);

    if (num !== null) {
        switch (profession[num].profession) {
            case Profession.AnalystProgrammer:
                return <AsAnalystProgrammer />;
            case Profession.SystemAnalyst:
                return <AsSystemAnalyst />;
            case Profession.ComputerNetAndSysEngineer:
                return <ComputerNetAndSysEngineer />;
            case Profession.DatabaseAdmin:
                return <DBAdmin />;
            case Profession.Developer:
                return <Developer />;
            case Profession.BusinessAnalyst:
                return <ICTBusinessAnalyst />;
            case Profession.ProjectManager:
                return <ICTProjectManager />;
            case Profession.SecuritySpecialist:
                return <ICTSecSpecialist />;
            case Profession.SupportEngineer:
                return <ICTSupportEngineer />;
            case Profession.MultimediaSpecialist:
                return <MultimediaSpecialist />;
            case Profession.NetworkAnalyst:
                return <NetworkAnalyst />;
            case Profession.SE:
                return <SoftwareEngineer />;

            default:
                break;
        }
    }

    return <div>Duties</div>;
};

export default Duties;
