import { signOut } from "firebase/auth";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isAuthSelector, UserIDSelector } from "../../feature/AuthSelector";
import { logout } from "../../feature/AuthSlice";
import { ProfessionSelector } from "../../feature/Modal/modalSelector";
import { reset } from "../../feature/Modal/modalSlice";
import { BroadSpecSelector, DetailedSpecSelector, EducationPointsSelector, IndustrySelector, SpecialisationSelector } from "../../feature/PostLogin/PostLoginSelector";
import { resetPostLogin, savePostAnswer } from "../../feature/PostLogin/postLoginSlice";
import { RootState } from "../../feature/store";
import { auth } from "../../firebase";
import Footer from "./PageContainer/Footer/Footer";
import Header from "./PageContainer/Header/Header";
import Title from "./PageContainer/Title/Title";
import { IndustryOption } from "./Pages/Industry/Industry";

interface PageContainerPropsType {
  children: React.ReactNode;
  title: string;
  enableFooter?: boolean;
  enableWelcomeUser?: boolean;
  enableLogoutBtn?: boolean;
  enableSaveBtn?: boolean;
  enableCSVBtn?: boolean;
}

const PageContainer = ({ children, title, enableFooter, enableWelcomeUser, enableLogoutBtn, enableSaveBtn, enableCSVBtn }: PageContainerPropsType) => {
  // const isOpen = useSelector(visibleSelectors);
  const isAuth = useSelector(isAuthSelector);
  const isAdmin = useSelector((state: RootState) => state.auth.admin);
  const profession = useSelector(ProfessionSelector);
  const industry = useSelector(IndustrySelector);
  const specialize = useSelector(SpecialisationSelector);
  const educationPoints = useSelector(EducationPointsSelector);
  const narrowSpecialisation = useSelector(DetailedSpecSelector);
  const broadSpecialisation = useSelector(BroadSpecSelector);
  const id = useSelector(UserIDSelector);
  const param = useParams();
  const currentPage = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNextPageClick = async () => {
    switch (currentPage.pathname) {
      case "/profile":
        navigate("/englang");
        break;
      case "/profession":
        if (profession.length === 0) alert("Please select profession");
        else {
          navigate("/duties/0");
        }

        break;
      case `/duties/${param?.dutiesID}`: {
        const currentDuties = param?.dutiesID;
        // console.log(currentDuties);
        const newDuties = currentDuties && +currentDuties + 1;
        if (newDuties) {
          if (profession.length < newDuties + 1) {
            navigate("/education");
          } else {
            navigate(`/duties/${newDuties}`);
          }
        }

        break;
      }

      case "/education":
        if (educationPoints === 20 || educationPoints === 15) navigate("/specialisation");
        else if (educationPoints === undefined || educationPoints === null) alert("Please select");
        else {
          navigate("/profile");
        }
        break;
      case "/specialisation":
        if (!specialize) alert("Please select");
        else if (specialize === "yes") navigate("/detailedspec");
        else if (specialize === "no") navigate("/profile");
        break;
      case "/detailedspec":
        if (narrowSpecialisation && broadSpecialisation && narrowSpecialisation !== "Select the most applicable answer") navigate("/profile");
        else {
          alert("Please select the most applicable answer.");
        }
        break;
      case "/englang":
        navigate("/industry");
        break;
      case "/industry":
        if (!industry) alert("Please select industry");
        else if (industry === IndustryOption.Information) navigate("/profession");
        else if (industry === IndustryOption.Others) navigate("/education");

        break;
      default:
        break;
    }
    dispatch(savePostAnswer(id));
  };

  useEffect(() => {
    // console.log(currentPage.pathname);
    if (currentPage.pathname.includes("/portal")) {
      if (!isAdmin) {
        navigate("/noaccess");
      }
    } else if (!isAuth && currentPage.pathname !== "/forgotpassword") {
      console.log(currentPage.pathname);
      signOut(auth);
      dispatch(logout());
      dispatch(reset());
      dispatch(resetPostLogin());
      navigate("/signin");
      //window.location.reload();
    } else if (currentPage.pathname === "/signin") {
      signOut(auth);
      dispatch(logout());
      dispatch(reset());
      dispatch(resetPostLogin());
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage.pathname]);

  return (
    <div className="relative h-screen w-full flex flex-col sm:space-y-8">
      <Header
        enableWelcomeUser={enableWelcomeUser}
        enableLogoutBtn={enableLogoutBtn}
        enableSaveBtn={enableSaveBtn}
        enableCSVButton={enableCSVBtn}
      />
      <Title title={title} />
      {children}
      <div className="flex justify-center items-center pb-10">{enableFooter && <Footer onClick={handleNextPageClick} />}</div>
    </div>
  );
};

export default PageContainer;
