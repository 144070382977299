import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFirestore } from "firebase/firestore";
import { AnimatePresence } from "framer-motion";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { FirestoreProvider, useFirebaseApp } from "reactfire";
import "./App.css";
import PageContainer from "./components/AuthPages/PageContainer";
import Education from "./components/AuthPages/Pages/Education/Education";
import EnglishLanguagePage from "./components/AuthPages/Pages/EnglishLanguagePage/EnglishLanguagePage";
import ForgotPassword from "./components/AuthPages/Pages/ForgotPassword";
import Industry from "./components/AuthPages/Pages/Industry/Industry";
import InvestmentPathway from "./components/AuthPages/Pages/InvestmentPathway";
import Profession from "./components/AuthPages/Pages/Profession";
import AsAnalystProgrammer from "./components/AuthPages/Pages/ProfessionalDuties/AsAnalystProgrammer";
import AsSystemAnalyst from "./components/AuthPages/Pages/ProfessionalDuties/AsSystemAnalyst";
import Duties from "./components/AuthPages/Pages/ProfessionalDuties/Duties";
import Profile from "./components/AuthPages/Pages/Profile";
import SignIn from "./components/AuthPages/Pages/SignIn";
import DetailedSpecialisation from "./components/AuthPages/Pages/Specialisation/DetailedSpecialisation";
import Specialisation from "./components/AuthPages/Pages/Specialisation/Specialisation";
import PortalManagement from "./components/PortalManagement/PortalManagement";
import ProfileModal from "./components/PortalManagement/ProfileModal";

import PageRender from "./PageRender";
import ProtectedRouteStep from "./protectedRoute/ProtectedStep";

const Navigate = () => {
  window.location.href = "https://www.australianpresencelegal.com//";
  return (
    <div className="flex flex-col justify-center items-center space-y-2 translate-y-1/2">
      <svg
        className="animate-spin h-20 w-20 "
        viewBox="0 0 24 24"
      >
        <FontAwesomeIcon
          icon={faSpinner}
          className="text-[#58061E]"
        />
      </svg>
      <h1 className="text text-center">Connecting to https://australianpresencelegal.com/</h1>
    </div>
  );
};

function App() {
  const firestoreInstance = getFirestore(useFirebaseApp());

  return (
    <AnimatePresence>
      <FirestoreProvider sdk={firestoreInstance}>
        <Routes>
          {/*   <Route path='/' element={<WelcomPage />} />
                    <Route path='/q1' element={<Q1 />} />
                    <Route path='/q2' element={<Q2 />} />
                    <Route path='/q3' element={<Q3 />} />
                    <Route path='/q4' element={<Q4 />} />
                    <Route path='/q5' element={<Q5 />} />
                    <Route path='/summary' element={<Summary />} />
                    <Route path='/signup' element={<SignUpContainer />} /> */}
          <Route
            path="/portal"
            element={
              <Suspense fallback={<div>loading ...</div>}>
                <PortalManagement />
              </Suspense>
            }
          >
            <Route
              path=":id"
              element={<ProfileModal />}
            />
          </Route>
          <Route
            path="/"
            element={
              <ProtectedRouteStep>
                <PageRender />
              </ProtectedRouteStep>
            }
          />

          <Route
            path="/:page"
            element={
              <ProtectedRouteStep>
                <PageRender />
              </ProtectedRouteStep>
            }
          />
          <Route
            path="/signin"
            element={
              <PageContainer title="Sign In">
                <SignIn />
              </PageContainer>
            }
          />
          <Route
            path="/p1"
            element={
              <PageContainer title="Sign In">
                <h1>Hello</h1>
              </PageContainer>
            }
          />
          <Route
            path="/forgotpassword"
            element={
              <PageContainer title="Forgot Password">
                <ForgotPassword />
              </PageContainer>
            }
          />
          <Route
            path="/englang"
            element={
              <PageContainer
                title="English Language"
                enableWelcomeUser
                enableLogoutBtn
                enableSaveBtn
                enableFooter
              >
                <EnglishLanguagePage />
              </PageContainer>
            }
          />

          <Route
            path="/profile"
            element={
              <PageContainer
                title="Profile"
                enableWelcomeUser
                enableLogoutBtn
                enableSaveBtn
              >
                <Profile />
              </PageContainer>
            }
          />
          <Route
            path="/profession"
            element={
              <PageContainer
                title="Profession"
                enableWelcomeUser
                enableLogoutBtn
                enableSaveBtn
                enableFooter
              >
                <Profession />
              </PageContainer>
            }
          />
          <Route
            path="/analystprog"
            element={
              <PageContainer
                title="Professional Duties"
                enableWelcomeUser
                enableLogoutBtn
                enableSaveBtn
                enableFooter
              >
                <AsAnalystProgrammer />
              </PageContainer>
            }
          />
          <Route
            path="/sysanalyst"
            element={
              <PageContainer
                title="Professional Duties"
                enableWelcomeUser
                enableLogoutBtn
                enableSaveBtn
                enableFooter
              >
                <AsSystemAnalyst />
              </PageContainer>
            }
          />
          <Route
            path="/education"
            element={
              <PageContainer
                title="Education"
                enableWelcomeUser
                enableLogoutBtn
                enableSaveBtn
                enableFooter
              >
                <Education />
              </PageContainer>
            }
          />
          <Route
            path="/specialisation"
            element={
              <PageContainer
                title="Specialisation"
                enableWelcomeUser
                enableLogoutBtn
                enableSaveBtn
                enableFooter
              >
                <Specialisation />
              </PageContainer>
            }
          />
          <Route
            path="/detailedspec"
            element={
              <PageContainer
                title="Specialisation"
                enableWelcomeUser
                enableLogoutBtn
                enableSaveBtn
                enableFooter
              >
                <DetailedSpecialisation />
              </PageContainer>
            }
          />
          <Route
            path="/industry"
            element={
              <PageContainer
                title="Industry"
                enableWelcomeUser
                enableLogoutBtn
                enableSaveBtn
                enableFooter
              >
                <Industry />
              </PageContainer>
            }
          />

          <Route
            path="/duties/:dutiesID"
            element={
              <PageContainer
                title="Duties"
                enableWelcomeUser
                enableLogoutBtn
                enableSaveBtn
                enableFooter
              >
                <Duties />
              </PageContainer>
            }
          />
          <Route
            path="/investmentpathway"
            element={<InvestmentPathway />}
          />
          <Route
            path="/apl"
            element={<Navigate />}
          />
        </Routes>
      </FirestoreProvider>
    </AnimatePresence>
  );
}

export default App;
