import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DocumentData} from "firebase/firestore";

export type InitialStateType = {
    userCredential: DocumentData | null;
    isAuth: boolean;
    response: DocumentData | null;
    docID: string;
    admin: boolean;
};

const initialState: InitialStateType = {
    userCredential: null,
    isAuth: false,
    response: null,
    docID: "",
    admin: false,
};

const AuthSlice = createSlice({
    name: "Auth",
    initialState,
    reducers: {
        signUp: (state, {payload}: PayloadAction<InitialStateType>) => {
            const {userCredential, isAuth} = payload;
            state.userCredential = userCredential;
            state.isAuth = isAuth;
        },
        authentication: (state, {payload}: PayloadAction<InitialStateType>) => {
            const {userCredential, isAuth, response, docID} = payload;
            state.userCredential = userCredential;
            state.isAuth = isAuth;
            state.response = response;
            state.docID = docID;
        },
        logout: (state) => {
            state.userCredential = null;
            state.isAuth = false;
            state.admin = false;
        },
        isAdmin: (state) => {
            state.admin = true;
        },
    },
});

export const {signUp, authentication, logout, isAdmin} = AuthSlice.actions;
export default AuthSlice.reducer;
