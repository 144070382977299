import React from "react";
import CountryInput from "./CountryInput";
import Input from "./Input";
import InputProfile from "./InputProfile";
import PathwayInput from "./PathwayInput";
import SigninInput from "./SigninInput";
import TextArea from "./TextArea";

interface FormikControlProps {
    control: string;
    name: string;
    type: string;
    label?: string;
    placeholder?: string;
    value?: string[] | string | number;
    disabled?: boolean;
    children?: React.ReactNode;
}

function FormikControl(props: FormikControlProps) {
    const {control, children, ...rest} = props;
    switch (control) {
        case "input":
            return <Input {...rest} />;
        case "SignInput":
            return <SigninInput {...rest} />;
        case "inputProfile":
            return <InputProfile {...rest} />;
        case "textarea":
            return <TextArea {...rest}>{children}</TextArea>;
        case "country":
            return <CountryInput {...rest} />;
        case "pathway":
            return <PathwayInput {...rest} />;
        default:
            return null;
    }
}

export default FormikControl;
