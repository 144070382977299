import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { authentication, isAdmin } from "../../../feature/AuthSlice";
import { getUserCredentials, signIn } from "../../../firebase";
import FormikControl from "../../SignUp/FormikControl";
import ButtonForm from "./ButtonForm";
const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      const auth = await signIn(values.email, values.password);

      if (auth) {
        if (auth?.user.emailVerified) {
          const { querySnapshotUser, querySnapshotResponse } = await getUserCredentials(auth.user.uid);

          if (querySnapshotUser && querySnapshotResponse) {
            querySnapshotUser.forEach((doc) => {
              querySnapshotResponse.forEach((response) => {
                let docID = response.id;
                try {
                  dispatch(
                    authentication({
                      userCredential: doc.data(),
                      isAuth: true,
                      response: response.data(),
                      docID,
                      admin: false,
                    })
                  );
                  setLoading(false);
                  localStorage.setItem("isAuth", "true");
                  navigate("/profile");
                } catch (error) {
                  setLoading(false);
                  console.log("Error in authentication during dispatch");
                }
              });
            });
          }
        } else if (auth?.user.email === "admin@gmail.com") {
          dispatch(isAdmin());
          navigate("/portal");
        } else {
          setLoading(false);
          alert("Please Verify Email");
        }
      } else {
        setLoading(false);
      }

      //loading
      //if authenticated set auth true
      //add full name
    } catch (error) {
      console.log({ error });
    }
  };
  return (
    <div className="flex justify-center items-center ">
      <div className="p-8 flex justify-center items-center border border-[#B08662] bg-white sm:p-20 sm:w-[466px] sm:h-[428px]">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form className="flex flex-col items-center space-y-3">
            <FormikControl
              control="SignInput"
              type="email"
              name="email"
              placeholder="email"
            />
            <FormikControl
              control="SignInput"
              type="password"
              name="password"
              placeholder="password"
            />

            <ButtonForm
              text="Sign In"
              loading={loading}
            />

            <div className="text-xs sm:text-base flex flex-row  text-[#707070]">
              Forgot your Password?
              <Link to="/forgotpassword">
                <span className="text-xs sm:text-base text-[#58061E] font-bold pl-1">Click here</span>
              </Link>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default SignIn;
