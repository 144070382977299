import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { visibleSelectors } from "../../../../feature/Modal/modalSelector";
import { close, open } from "../../../../feature/Modal/modalSlice";
import { calculatePoints, saveSpecialisation } from "../../../../feature/PostLogin/postLoginSlice";
import Modal from "../../../Modal/Modal";
const Specialisation = () => {
  const isOpen = useSelector(visibleSelectors);
  const dispatch = useDispatch();

  const _handleMouseEnter = () => {
    dispatch(open({ buttonType: "Close" }));
  };
  const yesRef = useRef<HTMLInputElement>(null);
  const noRef = useRef<HTMLInputElement>(null);

  const _handleChange = () => {
    const yes = yesRef.current;
    const no = noRef.current;

    if (yes?.checked) {
      dispatch(saveSpecialisation(yes.id));
      dispatch(
        calculatePoints({
          specialisationPoints: 10,
        })
      );
    } else if (no?.checked) {
      dispatch(saveSpecialisation(no.id));
      dispatch(
        calculatePoints({
          specialisationPoints: 0,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(saveSpecialisation(undefined));
    dispatch(close());
  }, [dispatch]);

  return (
    <>
      <div className="flex flex-col space-y-4 px-4 sm:px-8 sm:space-y-8 lg:flex-row lg:space-y-0 lg:space-x-10 xl:space-x-20 lg:pb-40 lg:pt-32 items-center justify-center">
        <div className="flex flex-col space-y-1 sm:flex-none text-[#5A5A5A]">
          <div className="flex">
            <span className="sm:text-xl lg:text-3xl xl:text-4xl sm:leading-9 sm:mr-2">Have you completed a specialisation in either of the following fields:</span>
            <motion.div
              whileHover={{
                scale: 1.1,
                textShadow: "0px 0px 2px #B08662",
                boxShadow: "0px 0px 5px #B08662",
                borderRadius: "9999px",
              }}
              onClick={_handleMouseEnter}
              className="h-3 w-3 inline-flex align-top 2xl:flex sm:w-6 sm:h-6 justify-center items-center border border-[#B08662] rounded-full"
            >
              <FontAwesomeIcon
                icon={faQuestion}
                className="text-[#B08662] w-2 h-2 sm:w-4 sm:h-4"
              />
            </motion.div>
          </div>
          <div className="flex flex-col text-sm lg:items-end sm:text-lg lg:text-2xl xl:text-3xl font-bold">
            <span>Natural and Physical Sciences</span>
            <span>Information Technology</span>
            <span>Engineering and Related Technologies</span>
          </div>
        </div>

        <div className="pb-4 sm:pb-0 flex flex-col  space-y-[9px]  sm:text-2xl text-[#5A5A5A]">
          <div className="flex items-center space-x-[13px]">
            <label htmlFor="yes">Yes</label>
            <input
              type="radio"
              id="yes"
              name="eng_language"
              value={1}
              ref={yesRef}
              onChange={_handleChange}
              className="sm:w-[30px] sm:h-[30px] accent-[#B88E27]"
            />
          </div>
          <div className="flex items-center space-x-[16px] sm:space-x-[18px]">
            <label htmlFor="no">No</label>
            <input
              type="radio"
              id="no"
              name="eng_language"
              value={0}
              ref={noRef}
              onChange={_handleChange}
              className="sm:w-[30px] sm:h-[30px] accent-[#B88E27]"
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        closeOnMouseLeave
      >
        <Scrollbars>
          <div className={"flex flex-col space-y-4 text-[#707070] p-9 sm:text-2xl"}>
            <div className="font-bold">Specialisations</div>
            <div>
              You may earn points towards your application if you can demonstrate a specialist education qualification. This requires completion of either a masters degree by research (as opposed to a
              masters by coursework) or a Doctoral degree (PhD) + the qualification must have included study of at least 2 academic years in Australia and be awarded by an Australian educational
              institution in a specified field.
            </div>
          </div>
        </Scrollbars>
      </Modal>
    </>
  );
};

export default Specialisation;
