import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DocumentData} from "firebase/firestore";
interface ListObjectProps {
    data: DocumentData;
    id: string;
}

interface InitialStateType {
    id: string;
    applicants: DocumentData;
}

const initialState: InitialStateType = {
    id: "",
    applicants: [] as DocumentData,
};

const PortalSlice = createSlice({
    name: "portal",
    initialState,
    reducers: {
        save: (
            state: InitialStateType,
            {payload}: PayloadAction<DocumentData>
        ) => {
            state.applicants = payload;
        },
        saveSelectedCard: (
            state: InitialStateType,
            {payload}: PayloadAction<string>
        ) => {
            state.id = payload;
        },
    },
});

export const {save, saveSelectedCard} = PortalSlice.actions;

export default PortalSlice.reducer;
