import {faQuestion} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {motion} from "framer-motion";
import {useEffect, useRef} from "react";
import Scrollbars from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {
    buttonModalSelector,
    visibleSelectors,
} from "../../feature/Modal/modalSelector";
import {close, open, setEnglish} from "../../feature/Modal/modalSlice";
import {activePage, pushHistory} from "../../feature/QSlice/QSlice";
import ArrowComponent from "../Custom/ArrowComponent";
import Modal from "../Modal/Modal";
import Title from "./Title";
const Q3 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const yesRef = useRef<HTMLInputElement>(null);
    const noRef = useRef<HTMLInputElement>(null);
    const visible = useSelector(visibleSelectors);
    const {buttonType} = useSelector(buttonModalSelector);
    const openInfoModal = () => {
        dispatch(open({buttonType: "Close"}));
    };

    useEffect(() => {
        dispatch(close());
        dispatch(pushHistory({id: 3, name: "q3"}));
        dispatch(activePage({id: 3, name: "q3"}));
    }, [dispatch]);

    const handleNextPage = () => {
        //console.log(yesRef.current?.checked, noRef.current?.checked);

        const yes = yesRef.current?.checked;
        const no = noRef.current?.checked;

        if (!(yes || no)) alert("Please select either Yes or No");
        else {
            if (no) {
                dispatch(open({buttonType: "Home Page", Q: "/"}));
            } else {
                //dispatch(open({buttonType: "Proceed", Q: "/q4"}));
                navigate("/q4");
                dispatch(setEnglish());
            }
        }
    };
    return (
        <motion.div
            initial={{
                y: 500,
                x: 0,
                opacity: 0,
            }}
            animate={{
                y: 0,
                x: 0,
                opacity: 1,
            }}
            transition={{
                type: "tween",
                ease: "anticipate",
                duration: 1,
            }}
            exit={{
                y: 0,
                y1: 1000,
                opacity: 0,
            }}
            className='h-screen flex flex-col items-center space-y-[20px] sm:space-y-[40px]'>
            <Title questionNumber='3' title='English Language' />
            <div className='flex  sm:w-[500px] lg:w-[1156px] justify-between items-center flex-col space-y-10 xl:flex-row xl:space-y-0'>
                <div className='text-center lg:flex lg:flex-row'>
                    <span className=' text-[#5A5A5A]  text-lg sm:text-[30px] sm:pr-1 lg:pr-4'>
                        Are you able to read, write and speak in English to a
                        competent standard?
                    </span>
                    <motion.div
                        whileHover={{
                            scale: 1.1,
                            textShadow: "0px 0px 2px #B08662",
                            boxShadow: "0px 0px 5px #B08662",
                            borderRadius: "9999px",
                        }}
                        className='inline-flex align-top w-4 h-4 lg:flex sm:w-6 sm:h-6 justify-center items-center border border-[#B08662] rounded-full cursor-pointer'
                        onClick={openInfoModal}>
                        <FontAwesomeIcon
                            icon={faQuestion}
                            className='text-[#B08662] w-3 h-3'
                        />
                    </motion.div>
                </div>
                <motion.div className='text-lg flex flex-col justify-end space-y-[9px] items-center  sm:text-2xl text-[#5A5A5A]'>
                    <div className='flex items-center space-x-[13px]'>
                        <label htmlFor='yes'>Yes</label>
                        <input
                            type='radio'
                            id='yes'
                            name='eng_language'
                            value={1}
                            className='w-6 h-6 sm:w-[30px] sm:h-[30px] accent-[#B88E27]'
                            ref={yesRef}
                        />
                    </div>
                    <div className='flex items-center space-x-[18px]'>
                        <label htmlFor='no'>No</label>
                        <input
                            type='radio'
                            id='no'
                            name='eng_language'
                            value={0}
                            className='w-6 h-6 sm:w-[30px] sm:h-[30px] accent-[#B88E27]'
                            ref={noRef}
                        />
                    </div>
                </motion.div>
            </div>
            <div className='' onClick={handleNextPage}>
                <ArrowComponent />
            </div>
            <div>
                <Modal isOpen={visible}>
                    <Scrollbars
                        autoHide={false}
                        className='w-[957px] h-[350px] sm:text-2xl mx-[13px] my-0 overflow-hidden mt-[31px] text-[#707070]'>
                        <div className='p-2 font-extrabold'>
                            English Language
                        </div>
                        <div className='leading-[29px] flex flex-col space-y-10 p-2'>
                            {buttonType !== "Home Page" ? (
                                <span>
                                    Applicants who are able to evidence
                                    citizenship of either the United Kingdom,
                                    United States of America, Canada, New
                                    Zealand or the Republic of Ireland are
                                    exempt from having to evidence English
                                    language proficiency. However, applicants
                                    may wish to sit for an approved English
                                    language test, as obtaining a competent
                                    level or higher in an approved English
                                    language test will derive points towards
                                    their migration
                                </span>
                            ) : (
                                <>
                                    <span>
                                        The Australian Government requires
                                        applicants for independent skilled visas
                                        to at least have competent level of
                                        English. Unfortunately your answer
                                        indicates that you are currently
                                        ineligible
                                    </span>
                                    <span>
                                        If you have significant wealth, or own a
                                        highly successful business, you may be
                                        eligible to migrate to Australia under a
                                        business, innovation or investment
                                        pathway. If you would like to understand
                                        your eligibility for a business,
                                        innovation or investment visa, please
                                        click
                                        <Link to='/investmentpathway'>
                                            <span className='text-[#0316F8] font-semibold'>
                                                {" here."}
                                            </span>
                                        </Link>
                                    </span>
                                </>
                            )}
                        </div>
                    </Scrollbars>
                </Modal>
            </div>
        </motion.div>
    );
};

export default Q3;
