import {createSelector} from "@reduxjs/toolkit";
import {DocumentData} from "firebase/firestore";
import {RootState} from "../store";

export const SelectedCardSelector = createSelector(
    (state: Pick<RootState, "portal">) => state.portal,
    ({applicants, id}) => {
        //console.log(applicants);
        return (
            applicants.find(
                (applicant: DocumentData) => applicant.uid === id
            ) ?? null
        );
    }
);
