import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    ComputeEducationScore,
    ComputeEnglishScore,
    SpecialisationSelectorPoints,
    TotalPointsSelector,
} from "../../../../feature/PostLogin/PostLoginSelector";
import {calculatePoints} from "../../../../feature/PostLogin/postLoginSlice";

const Title = ({title}: {title: string}) => {
    const englishLangpts = useSelector(ComputeEnglishScore);
    const educpts = useSelector(ComputeEducationScore);
    const totalPoints = useSelector(TotalPointsSelector);
    const specialisationPoints = useSelector(SpecialisationSelectorPoints);
    const [points, setPoints] = useState(0);
    const dispatch = useDispatch();
    useEffect(() => {
        switch (title) {
            case "English Language":
                setPoints(englishLangpts.points);
                dispatch(calculatePoints({englang: englishLangpts}));
                break;
            case "Education":
                setPoints(educpts);
                dispatch(calculatePoints({education: educpts}));
                break;
            case "Profile":
                setPoints(totalPoints);
                break;
            case "Specialisation":
                setPoints(specialisationPoints);
                dispatch(
                    calculatePoints({
                        specialisationPoints,
                    })
                );
                break;
            default:
                setPoints(0);
                break;
        }
    }, [totalPoints, educpts, englishLangpts.points, dispatch, points, title]);

    return (
        <div className='text-2xl sm:text-3xl  md:text-4xl lg:text-6xl text-[#58061E] font-semibold leading-[68px] text-center'>
            {/* {`$(${points})`} */}
            {title}
        </div>
    );
};

export default Title;
