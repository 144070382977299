import CheckBox from "../CheckBox";

const ICTBusinessAnalyst = () => {
  return (
    <div className="flex flex-col justify-center items-center self-center space-y-4 capitalize-first sm:w-2/3 max-w-screen-lg">
      <div className="sm:text-xl lg:text-3xl xl:text-4xl text-[#5A5A5A] text-center">Select the professional duties you can demonstrate that you regularly perform as an ICT Business Analyst</div>

      <CheckBox>working with users to formulate and document business requirements</CheckBox>
      <CheckBox>identifying, investigating, and analyzing business processes, procedures and work practices</CheckBox>
      <CheckBox>identifying and evaluating inefficiencies and recommending optimal business practices, and system functionality and behavior</CheckBox>
      <CheckBox>using project management methodologies, principles and techniques to develop project plans and to cost, resource and manage projects</CheckBox>
      <CheckBox>
        taking responsibility for deploying functional solutions, such as creating, adopting and implementing system test plans, which ensure acceptable quality and integrity of the system
      </CheckBox>
      <CheckBox>creating user and training documentation, and conducting formal training classes</CheckBox>
      <CheckBox>developing functional specifications for use by system developers</CheckBox>
      <CheckBox>using data and process modeling techniques to create clear system specifications for the design and development of system software</CheckBox>
      <CheckBox>acting as a central reference and information source, providing guidance and assistance in the system project decision making process</CheckBox>
    </div>
  );
};

export default ICTBusinessAnalyst;
