import {Dispatch, SetStateAction, useState} from "react";

interface DropdownProps {
    value: string | null;
    isOpen: boolean;

    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setValue: Dispatch<SetStateAction<string | null>>;
}

const UseDropdownHook = () => {
    const [value, setValue] = useState<string | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const object: DropdownProps = {
        value,
        isOpen,
        setValue,
        setIsOpen,
    };
    return object;
};

export default UseDropdownHook;
