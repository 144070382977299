import classNames from "classnames";
import {motion} from "framer-motion";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {buttonModalSelector} from "../../feature/Modal/modalSelector";
import {close} from "../../feature/Modal/modalSlice";
const Modal = ({
    isOpen,
    children,
    disabledCloseBtn,
    closeOnMouseLeave,
    large,
}: {
    isOpen: boolean;
    children: React.ReactNode;
    disabledCloseBtn?: boolean;
    closeOnMouseLeave?: boolean;
    large?: boolean;
}) => {
    const dispatch = useDispatch();
    const {buttonType, question} = useSelector(buttonModalSelector);
    const navigate = useNavigate();
    const param = useParams();
    if (!isOpen) return null;

    const handleClick = () => {
        if (buttonType === "Close") {
            // console.log(Object.keys(param));
            if (Object.keys(param)[0] === "id") {
                navigate("/portal");
            }
            dispatch(close());
        } else if (buttonType === "Proceed") {
            dispatch(close());
            navigate(question);
        } else if (buttonType === "Home Page") {
            dispatch(close());
            navigate(question);
        } else if (buttonType === "registered") {
            dispatch(close());
            navigate(question);
        }
        if (buttonType === "apl") {
            dispatch(close());
            navigate(question);
        }
    };

    const _handleOnMouseLeave = () => {
        if (closeOnMouseLeave) {
            if (Object.keys(param)[0] === "id") {
                navigate("/portal");
            }
            dispatch(close());
        }
    };

    return (
        <motion.div
            role='presentation'
            className='fixed top-10 sm:top-14 left-0  h-screen w-full flex justify-center items-center  z-20 '
            onClick={_handleOnMouseLeave}
            onLoadedData={_handleOnMouseLeave}>
            <motion.div
                initial={{
                    y: 0,
                    x: 0,
                    opacity: 0.5,
                }}
                animate={{
                    y: 0,
                    x: 0,
                    opacity: 1,
                }}
                transition={{
                    duration: 0.5,
                }}
                role='presentation'
                className={classNames(
                    "flex flex-col relative  items-center bg-white rounded-sm w-[500px] h-[410px] sm:w-[600px] md:[760px]  z-[1003] drop-shadow-lg",
                    {
                        "lg:w-[1008px] lg:h-[458px]": !large,
                        "lg:w-[1600px] lg:h-[600px]": large,
                    }
                )}
                onClick={(event) => event.stopPropagation()}>
                {children}
                {!disabledCloseBtn && (
                    <div
                        className='self-end m-2 mx-3 flex flex-row justify-center items-center h-[51px] w-[145px] bg-[#58061E] opacity-100 text-white text-lg tracking-normal rounded-full cursor-pointer hover:bg-[#7a082a] '
                        onClick={handleClick}>
                        {buttonType === "registered" || buttonType === "apl"
                            ? "Close"
                            : buttonType}
                    </div>
                )}
            </motion.div>
        </motion.div>
    );
};

export default Modal;
