import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React, { useEffect, useReducer, useRef } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { visibleSelectors } from "../../../feature/Modal/modalSelector";
import { addProfession, close, open, removeProfession } from "../../../feature/Modal/modalSlice";
import Modal from "../../Modal/Modal";

interface ProfessionListPropTypes {
  profession: string;
  description?: string;

  alternate?: string;
  specialization?: string;

  setModalProfession: React.Dispatch<any>;
}

const ProfessionList = ({ profession, description, setModalProfession, alternate, specialization }: ProfessionListPropTypes) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const _handleMouseOver = () => {
    if (profession)
      setModalProfession({
        type: "UPDATE",
        payload: {
          title: profession,
          description: description,
          alternate,
          specialization,
        },
      });

    dispatch(open({ buttonType: "Close" }));
  };

  const handleOnChange = () => {
    const profession = inputRef.current?.value;
    const isSelected = inputRef.current?.checked;
    if (isSelected) {
      dispatch(addProfession({ profession }));
    } else {
      dispatch(removeProfession({ profession }));
    }
  };

  return (
    <>
      <div className="flex justify-between py-1 border-b border-[#B08662]">
        <div className="flex -space-y-1 mt-2 mr-2 sm:mt-0 sm:mr-0">
          <span className="text-[#5A5A5A] sm:text-lg lg:text-2xl xl:text-3xl">{profession}</span>
          <motion.div
            onClick={_handleMouseOver}
            whileHover={{
              scale: 1.1,
              textShadow: "0px 0px 2px #B08662",
              boxShadow: "0px 0px 5px #B08662",
              borderRadius: "9999px",
            }}
            className="flex ml-1 w-2 h-2 p-1.5 sm:p-2 sm:w-3 sm:h-3 justify-center items-center border-2 border-[#B08662] rounded-full cursor-pointer"
          >
            <FontAwesomeIcon
              icon={faQuestion}
              className="w-2 h-2 text-[#B08662] sm:w-3 sm:h-3"
            />
          </motion.div>
        </div>
        <div>
          <input
            ref={inputRef}
            type={"checkbox"}
            className="sm:w-[30px] sm:h-[45px] accent-[#B88E27] "
            name={profession}
            id={profession}
            value={profession}
            onChange={handleOnChange}
          />
        </div>
      </div>
    </>
  );
};

const initialState = {
  title: null,
  description: null,
  alternate: null,
  profession: null,
  specialization: null,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "UPDATE":
      return action.payload;
    default:
      return state;
  }
};

const Profession = () => {
  const isOpen = useSelector(visibleSelectors);
  //const [description, setDescription] = useState<string | null>(null);
  const dispatchRedux = useDispatch();
  const [profession, dispatch] = useReducer(reducer, initialState);
  //console.log(profession);

  useEffect(() => {
    dispatchRedux(close());
  }, [dispatchRedux]);
  return (
    <>
      <div className="flex flex-col mb-2  sm:mb-0 space-y-10 justify-center items-center ">
        <div className="sm:text-xl lg:text-3xl xl:text-4xl text-center px-4 sm:px-0 text-[#5A5A5A]">Which of the occupations below align with your employment history</div>
        <div className="sm:w-2/3 max-w-screen-lg">
          <ProfessionList
            setModalProfession={dispatch}
            profession="Analyst Programmer"
            alternate="Programmer Analyst"
            description="Analyses user needs, produces requirements documentation and system plans, and encodes, tests, debugs, maintains and documents programs and applications."
          />
          <ProfessionList
            setModalProfession={dispatch}
            profession="Computer Network and Systems Engineer"
            specialization="Computer Network Engineer, Computer Systems Integrator"
            description="Plans, develops, deploys, tests and optimizes network and system services, taking responsibility for configuration management and overall operational readiness of network systems, especially environments with multiple operating systems and configurations, and provides troubleshooting and fault-finding services for network problems."
          />
          <ProfessionList
            setModalProfession={dispatch}
            profession="Database Administrator"
            alternate="Database Operator, Database Specialist, Database Support, DBA"
            specialization="Database Analyst"
            description="Plans, develops, configures, maintains and supports an organisation's database management system in accordance with user requirements ensuring optimal database integrity, security, backup, reliability and performance."
          />
          <ProfessionList
            setModalProfession={dispatch}
            profession="Developer Programmer"
            description="Interprets specifications, technical designs and flow charts, builds, maintains and modifies the code for software applications, constructs technical specifications from a business functional model, and tests and writes technical documentation."
            alternate="Applications Developer, ICT Developer, ICT Programmer"
            specialization="Cyber Security Developer, Database Developer, Database Programmer (Systems), Network Programmer, Software Developer, Software Programmer"
          />
          <ProfessionList
            setModalProfession={dispatch}
            profession="ICT Business Analyst"
            alternate="BA (ICT), Business Consultant (ICT)"
            specialization="Business Systems Analyst"
            description="Identifies and communicates with users to formulate and produce a requirements specification to create system and software solutions."
          />
          <ProfessionList
            setModalProfession={dispatch}
            profession="ICT Project Manager"
            specialization="ICT Development Manager, ICT Security Project Manager"
            description="Plans, organises, directs, controls and coordinates quality accredited ICT projects. Accountable for day-to-day operations of resourcing, scheduling, prioritization and task coordination, and meeting project milestones, objectives and deliverables within agreed timeframes and budgets."
          />
          <ProfessionList
            setModalProfession={dispatch}
            profession="ICT Security Specialist"
            alternate="Security Administrator"
            specialization="Information Technology, Security Manager"
            description="Establishes, manages and administers an organisation's ICT security policy and procedures to ensure preventive and recovery strategies are in place, and minimize the risk of internal and external security threats."
          />
          <ProfessionList
            setModalProfession={dispatch}
            profession="ICT Support Engineer"
            alternate="Support Analyst, Support Architect"
            description="Develops support procedures and strategies for systems, networks, operating systems and applications development, solves problems and provides technical expertise and direction in support of system infrastructure and process improvements, and diagnoses and resolves complex system problems."
          />
          <ProfessionList
            setModalProfession={dispatch}
            profession="Multimedia Specialist"
            alternate="Electronic Game Developer, Multimedia Developer, Multimedia Programmer"
            description="Multimedia Specialists create computer animation, audio, video and graphic image files for multimedia presentations, games, motion pictures, CD-ROMs, information kiosks and the web, and plan, produce and maintain websites and web applications using web programming, scripting, authoring, content management and file transfer software."
          />
          <ProfessionList
            setModalProfession={dispatch}
            profession="Network Analyst"
            alternate="Network Designer, Network Strategist"
            specialization="Network Architect, Network Consultant"
            description="Researches and analyses network architecture, and recommends policies and strategies for designing, planning and coordinating an organisation's network such as the total system environment and architecture. May also perform operational tasks such as monitoring system performance, software and hardware upgrades, backups, support and network maintenance."
          />
          <ProfessionList
            setModalProfession={dispatch}
            profession="Software Engineer"
            alternate="Software Architect, Software Designer"
            specialization="Computer Applications Engineer, Systems Architect"
            description="Designs, develops, modifies, documents, tests, implements, installs and supports software applications and systems"
          />
          <ProfessionList
            setModalProfession={dispatch}
            profession="Systems Analyst"
            description="Evaluates processes and methods used in existing ICT systems, proposes modifications, additional system components or new systems to meet user needs as expressed in specifications and other documentation."
          />
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        closeOnMouseLeave
      >
        <Scrollbars
          autoHide={false}
          className="w-[957px] h-auto sm:text-2xl mx-[13px] my-0 overflow-hidden mt-[31px] text-[#707070]"
        >
          <div className={" flex flex-col space-y-4 text-[#707070] p-9 sm:text-2xl sm:h-[400px]"}>
            <div className="font-bold">{profession?.title}</div>
            <div>{profession?.description}</div>

            {profession?.alternate && <div>Alternate Title: {profession?.alternate}</div>}
            {profession?.specialization && <div>Specializations: {profession?.specialization}</div>}
          </div>
        </Scrollbars>
      </Modal>
    </>
  );
};

export default Profession;
