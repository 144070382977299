import { init, track } from "fbq";
import { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import { visibleSelectors } from "../../feature/Modal/modalSelector";

import Modal from "../Modal/Modal";

export default function Thankyou() {
  useEffect(() => {
    init("723342295671008");
    track("Lead");
  }, []);

  const visible = useSelector(visibleSelectors);
  return (
    <div>
      <Modal isOpen={visible}>
        <Scrollbars
          autoHide={false}
          className="overscroll-x-none w-[957px] h-[350px] sm:text-2xl mx-[13px] my-0 overflow-hidden mt-[31px] text-[#707070]"
        >
          <div className="p-2 font-bold">Thank you</div>
          <div className="leading-[29px] flex flex-col space-y-10 p-2">
            <span>
              Thank you for signing up. You'll receive a confirmation email
              shortly
            </span>
          </div>
        </Scrollbars>
      </Modal>
    </div>
  );
}
