export const COLUMNS = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "FirstName",
    accessor: "first_name",
  },
  {
    Header: "Lastname",
    accessor: "last_name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Gender",
    accessor: "gender",
  },
  {
    Header: "IP Address",
    accessor: "ip_address",
  },
];

export const columns = [
  {
    Header: "Firstname",
    accessor: "firstName",
  },
  {
    Header: "Surname",
    accessor: "surname",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Age",
    accessor: "userResponse.age",
  },
  {
    Header: "Citizenship",
    accessor: "userResponse.countries",
    Cell: ({ value }: any) => {
      return value ? value.join() : null;
    },
  },
  {
    Header: "Degree",
    accessor: "userResponse.degree",
    Cell: (value: boolean) => {
      //console.log(value);
      return value ? "Yes" : "No";
    },
  },
  {
    Header: "English Language",
    accessor: "userResponse.postAnswer.currentPoints.englang.proficiency",
  },
  {
    Header: "Education",
    accessor: "userResponse.postAnswer.Education.label",
  },
  {
    Header: "Years Experience",
    accessor: "userResponse.workExperience",
  },
  {
    Header: "Profession",
    accessor: "userResponse.profession",
    Cell: ({ value }: any) => {
      if (value) {
        const profession = value.map((val: any) => val.profession);
        return profession.join();
      }

      return "";
    },
  },
  {
    Header: "Specialisation",
    accessor: "userResponse.postAnswer.DetailedSpec.broad",
  },
  {
    Header: "UID",
    accessor: "uid",
  },
  {
    Header: "UserDocument ID",
    accessor: "NO_ID_FIELD",
  },
  {
    Header: "ResponseDocument ID",
    accessor: "userResponse.NO_ID_FIELD",
  },
  {
    Header: "Stage",
    accessor: "userResponse.stage",
  },
  {
    Header: "Points",
    accessor: "totalPoints",
  },
];

export const CSVHeaders = [
  {
    label: "Firstname",
    key: "firstName",
  },
  {
    label: "Surname",
    key: "surname",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "phone",
    key: "phone",
  },
  {
    label: "Age",
    key: "userResponse.age",
  },
  {
    label: "Citizenship",
    key: "userResponse.countries",
  },
  {
    label: "Degree",
    key: "userResponse.degree",
  },
  {
    label: "English Language",
    key: "userResponse.postAnswer.currentPoints.englang.proficiency",
  },
  {
    label: "Education",
    key: "userResponse.postAnswer.Education.label",
  },
  {
    label: "Years Experience",
    key: "userResponse.workExperience",
  },
  {
    label: "Profession",
    key: "profession",
  },
  {
    label: "Specialisation",
    key: "userResponse.postAnswer.DetailedSpec.broad",
  },

  {
    label: "UID",
    key: "uid",
  },
  {
    label: "UserDocumentID",
    key: "NO_ID_FIELD",
  },
  {
    label: "ResponseDocumentID",
    key: "userResponse.NO_ID_FIELD",
  },
  {
    label: "Stage",
    key: "userResponse.stage",
  },
  {
    label: "Points",
    key: "totalPoints",
  },
];
