import {ErrorMessage, Field, FieldAttributes} from "formik";
import TextError from "./TextError";

function CountryInput(props: FieldAttributes<any>) {
    const {label, placeholder, name, value, disabled, ...rest} = props;

    return (
        <div className=''>
            <div className='flex flex-col items-center sm:flex-row sm:items-start justify-center'>
                <label
                    htmlFor={name}
                    className='text-lg sm:text-xl sm:w-1/3 text-[#5A5A5A]'>
                    {label}
                </label>
                <div className='flex flex-col items-center justify-between space-y-4'>
                    {value?.map((d: any, i: any) => {
                        return (
                            <Field
                                key={i}
                                id={i}
                                name={i}
                                {...rest}
                                className='text-base p-1 border border-[#DED1C5] bg-white outline-none sm:w-[350px] sm:py-2.5 sm:pl-4 sm:text-lg sm:ml-5 text-[#A09FA1]'
                                placeholder={placeholder}
                                value={d}
                                disabled={disabled}
                            />
                        );
                    })}
                </div>
            </div>

            <ErrorMessage component={TextError} name={name} />
        </div>
    );
}

export default CountryInput;
