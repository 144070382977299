import {motion} from "framer-motion";

interface ButtonPropsType {
    title: string;
    onClick?: () => void;
}

const Button = ({title, onClick}: ButtonPropsType) => {
    return (
        <motion.div
            whileHover={{
                scale: 1.1,
                textShadow: "0px 0px 8px rgb(255,255,255)",
                boxShadow: "0px 0px 8px rgb(255,255,255)",
                borderRadius: "9999px",
            }}
            onClick={onClick}
            className='w-full flex justify-center px-2 rounded-full cursor-pointer items-center text-center sm:px-3 sm:py-1   border-2 border-[#58061E]   text-[#58061E] sm:text-xs  md:text-sm  lg:flex-row lg:w-36 lg:h-12 lg:text-lg  lg:leading-5  lg:px-9 '>
            {title}
        </motion.div>
    );
};

export default Button;
