import CheckBox from "../CheckBox";

const SoftwareEngineer = () => {
  return (
    <div className="flex flex-col justify-center items-center self-center space-y-4 capitalize-first sm:w-2/3 max-w-screen-lg">
      <div className="sm:text-xl lg:text-3xl xl:text-4xl text-[#5A5A5A] text-center">Select the professional duties you can demonstrate that you regularly perform as a Software Engineer</div>

      <CheckBox>researching, consulting, analyzing and evaluating system program needs</CheckBox>
      <CheckBox>identifying technology limitations and deficiencies in existing systems and associated processes, procedures and methods</CheckBox>
      <CheckBox>
        testing, debugging, diagnosing and correcting errors and faults in an applications programming language within established testing protocols, guidelines and quality standards to ensure
        programs and applications perform to specification
      </CheckBox>
      <CheckBox>writing and maintaining program code to meet system requirements, system designs and technical specifications in accordance with quality accredited standards</CheckBox>
      <CheckBox>writing, updating and maintaining technical program, end user documentation and operational procedures</CheckBox>
      <CheckBox>
        providing advice, guidance and expertise in developing proposals and strategies for software design activities such as financial evaluation and costing for recommending software purchases and
        upgrades
      </CheckBox>
    </div>
  );
};

export default SoftwareEngineer;
