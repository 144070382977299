import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {Dispatch, SetStateAction} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {useMatch} from "react-router-dom";
import {Q2OptionsType} from "../../Questions/type";
import DropdownTray from "./DropdownTray";

interface DropdownTrayPros {
    onChange: Dispatch<SetStateAction<null | string>>;
    options: Q2OptionsType[];
    value: string | null;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    search?: boolean;
    setSize?: boolean;
}

const DropdownContainer = ({
    onChange,
    options,
    value,
    setIsOpen,
    search,
    setSize,
}: DropdownTrayPros) => {
    const ref = useOnclickOutside(() => {
        setIsOpen(false);
    });

    const parm = useMatch("/:page");
    const page = parm?.params.page;

    return (
        <div
            ref={ref}
            className={classNames(
                "absolute  border-2 -left-0.5   overflow-auto bg-white z-30",
                {
                    "top-6 w-[227px] sm:h-[200px] sm:w-[347px]  sm:top-11":
                        !setSize,
                    "sm:w-[456px] sm:top-[34px]": setSize,
                    "top-6": page === "education" || page === "detailedspec",
                }
            )}>
            {search && (
                <div className='flex flex-row mb-2 p-1 '>
                    <input
                        type='text'
                        placeholder='search'
                        className='outline-none'
                    />
                    <FontAwesomeIcon icon={faSearch} />
                </div>
            )}
            <div>
                {options &&
                    options.map((o, index) => {
                        return value === o.value ? (
                            <DropdownTray
                                key={index}
                                onChange={onChange}
                                label={o}
                                selected
                                setIsOpen={setIsOpen}
                                setSize={setSize}
                            />
                        ) : (
                            <DropdownTray
                                key={index}
                                onChange={onChange}
                                label={o}
                                setIsOpen={setIsOpen}
                                setSize={setSize}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default DropdownContainer;
