import { useMemo } from "react";
import { CSVLink } from "react-csv";
import { useSortBy, useTable } from "react-table";
import { CSVHeaders } from "./columns";

interface TableProps {
  columnsProps: Array<any>;
  dataProps: Array<any>;
}

const Table = ({ dataProps, columnsProps }: TableProps) => {
  const columns = useMemo(() => columnsProps, [columnsProps]);
  const data = useMemo(() => dataProps, [dataProps]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        // @ts-ignore
        columns,
        data,
      },
      useSortBy
    );

  const csvData = useMemo(
    () =>
      rows.map((row) => {
        prepareRow(row);
        let profession = "";
        Object.keys(row.values).map((key) => {
          if (key === "userResponse.profession") {
            //profession = row.values.userResponse.profession
            profession = row.values["userResponse.profession"]
              ?.map((p: any) => p.profession)
              .join();

            //console.log(profession);
          }
          return key;
        });
        return { ...row.values, profession };
      }),
    [rows]
  );

  const isEven = (idx: number) => {
    return idx % 2 === 0;
  };
  return (
    <>
      <CSVLink
        data={csvData}
        headers={CSVHeaders}
        filename="APL_Applicant"
        className="w-32 h-20 text-sm self-end mr-56 p-2  flex flex-row justify-center  items-center   bg-[#58061E] opacity-100 text-white  tracking-normal rounded-full"
      >
        Download to CSV
      </CSVLink>
      {/*    <div
        className="relative mx-20 p-2 pt-10 h-full overflow-x-auto shadow-2xl sm:rounded-lg"
      
      >
        <table
          {...getTableProps}
          className="w-full text-base table-fixed text-gray-500 dark:text-gray-400"
        >
          <thead className="text-base font-lato text-gray-200 uppercase bg-gray-500">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    scope="col"
                    className="px-6 py-3 text-center w-64"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, idx) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classNames("bg-white border-b ", {
                    "bg-white": !isEven(idx),
                    "bg-gray-100": isEven(idx),
                  })}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="px-2 py-4 text-center"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div> */}
    </>
  );
};

export default Table;
