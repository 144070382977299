import Scrollbars from "react-custom-scrollbars";
import {useSelector} from "react-redux";
import {visibleSelectors} from "../../feature/Modal/modalSelector";
import {SelectedCardSelector} from "../../feature/Portal/PortalSelector";
import {RootState} from "../../feature/store";
import Modal from "../Modal/Modal";
import Profile from "./Profile";

const ProfileModal = () => {
    const visible = useSelector(visibleSelectors);
    const data = useSelector(SelectedCardSelector);
    const id = useSelector((state: RootState) => state.portal.id);

    return (
        <Modal isOpen={visible} closeOnMouseLeave large>
            <Scrollbars>{data && <Profile />}</Scrollbars>
        </Modal>
    );
};

export default ProfileModal;
