import {motion} from "framer-motion";

const ArrowComponent = () => {
    return (
        <motion.div
            whileHover={{
                scale: 1.1,
                textShadow: "0px 0px 8px rgb(88,6,30)",
                boxShadow: "0px 0px 8px rgb(88,6,30)",
                borderRadius: "6px",
            }}
            className='mt-3 relative w-16 h-16 sm:w-32 sm:h-32 flex justify-center items-center sm:mt-14 lg:mt-52'>
            {/*     <img
                src={arrowIcon}
                alt='arrow'
                className='absolute -z-10 w-16 h-16'
            />
            <img
                src={hexIcon}
                alt='hexagon'
                className='absolute -z-10 rotate-90 w-30 h-30'
            /> */}
            <img
                src='/images/arrowhex.png'
                alt='hexagon'
                className='absolute -z-10  w-30 h-30'
            />
        </motion.div>
    );
};

export default ArrowComponent;
