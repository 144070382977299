import {motion} from "framer-motion";
import {useEffect, useRef} from "react";
import Scrollbars from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";
import {useDispatch, useSelector} from "react-redux";
import {
    currentPoints,
    summarySelector,
    visibleSelectors,
} from "../../feature/Modal/modalSelector";
import {open} from "../../feature/Modal/modalSlice";
import {activePage, pushHistory} from "../../feature/QSlice/QSlice";
import ArrowComponent from "../Custom/ArrowComponent";
import NavBar from "../HomePage/NavBar";
import Modal from "../Modal/Modal";
const Summary = () => {
    const {age, countries, workExperience, degree} =
        useSelector(summarySelector);
    const confirmationRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const visible = useSelector(visibleSelectors);
    const points = useSelector(currentPoints);
    useEffect(() => {
        dispatch(pushHistory({id: 6, name: "summary"}));
        dispatch(activePage({id: 6, name: "summary"}));
    }, [dispatch]);
    const handleConfirmation = () => {
        const confirmed = confirmationRef?.current?.checked;

        if (confirmed) {
            dispatch(open({buttonType: "Proceed", Q: "/signup"}));
        } else {
            alert("Please confirm the details");
        }
    };
    return (
        <motion.div
            initial={{
                y: 500,
                x: 0,
                opacity: 0,
            }}
            animate={{
                y: 0,
                x: 0,
                opacity: 1,
            }}
            transition={{
                type: "tween",
                ease: "anticipate",
                duration: 1,
            }}
            exit={{
                y: 0,
                y1: 1000,
                opacity: 0,
            }}
            className='relative h-screen flex flex-col items-center space-y-[5px] sm:space-y-[20px] '>
            <div className='mb-[20px]  w-screen flex justify-center items-center'>
                <NavBar />
            </div>
            <div className='text-3xl sm:text-[57px] text-[#58061E]  leading-[68px]'>
                Summary
            </div>

            {!visible && (
                <>
                    <div className='flex flex-col justify-center items-start   space-y-6 text-[#5A5A5A] ml-4 md:text-xl xl:w-[1003px] xl:h-[360px] xl:text-[30px] xl:leading-[36px]'>
                        <span className=' font-bold'>
                            {/* {`Summary of responses ( Total Points : ${points})`} */}
                            Summary of responses
                        </span>

                        <span>
                            Below is a summary of the answers you have provided
                        </span>
                        <div className='flex flex-col'>
                            <span>- You are currently {age} years of age</span>
                            <span>
                                - You’re a citizen of the following countries:
                                {countries.length > 0 &&
                                    countries.map((country, i) => {
                                        if (i < countries.length - 1) {
                                            return (
                                                <span>{` ${country},`}</span>
                                            );
                                        }

                                        return <span>{` ${country}`}</span>;
                                    })}
                            </span>
                            <span>
                                - You speak, write and understand English at a
                                competent level
                            </span>
                            <span>
                                - You have {workExperience} years experience in
                                your profession or trade
                            </span>
                            {degree && (
                                <span>
                                    - You have a degree or diploma level
                                    qualification in your profession, or have
                                    obtained a recognised trade certification
                                    related to your profession
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='pt-4 flex justify-between items-center space-x-4 mx-4 lg:mx-0 sm:text-xl xl:text-[24px] text-[#707070]'>
                        <label htmlFor='confirmation'>
                            Select this box to confirm that the above
                            information is true and accurate before proceeding
                        </label>
                        <input
                            type={"checkbox"}
                            id='confirmation'
                            className='w-[45px] h-[45px] accent-[#B88E27]'
                            ref={confirmationRef}
                        />
                    </div>
                </>
            )}
            <div
                className='md:absolute md:top-[550px]'
                onClick={handleConfirmation}>
                <ArrowComponent />
            </div>
            <div>
                <Modal isOpen={visible}>
                    <Scrollbars
                        autoHide={false}
                        className='overscroll-x-none w-[957px] h-[350px] sm:text-2xl mx-[13px] my-0 overflow-hidden mt-[31px] text-[#707070]'>
                        <div className='p-5 font-bold'>Acknowledgement</div>
                        <div className='leading-[29px] flex flex-col space-y-10 p-5 text-justify'>
                            <span className='px-2 sm:px-0'>
                                Congratulations – you have received a positive
                                preliminary assessment. To understand the
                                specific areas of eligibility, we will be asking
                                you more detailed questions about your work,
                                education and your family unit. You may need to
                                save your progress from time to time so that you
                                can obtain the information we need. To do this,
                                and to keep your information secure, please
                                create an account by filling in the details on
                                the following page.
                            </span>
                        </div>
                    </Scrollbars>
                </Modal>
            </div>
        </motion.div>
    );
};

export default Summary;
