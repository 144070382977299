import SweetAlert2 from "react-sweetalert2";

interface ErrorProps {
    children: React.ReactNode;
    open: boolean;
    clearError: (v: string) => void;
}

const Error = ({children, open, clearError}: ErrorProps) => {
    if (!open) return null;
    return (
        <SweetAlert2
            heightAuto
            confirmButtonColor='#cf5c5c'
            icon='error'
            show={open}
            position='center'
            onConfirm={() => clearError("")}>
            <div> {children}</div>
        </SweetAlert2>
    );
};

export default Error;
