import CheckBox from "../CheckBox";

const MultimediaSpecialist = () => {
  return (
    <div className="flex flex-col justify-center items-center self-center space-y-4 capitalize-first sm:w-2/3 max-w-screen-lg">
      <div className="sm:text-xl lg:text-3xl xl:text-4xl text-[#5A5A5A] text-center">Select the professional duties you can demonstrate that you regularly perform as a Multimedia Specialist</div>

      <CheckBox>
        analyzing, designing and developing Internet sites applying a mixture of artistry and creativity with software programming and scripting languages and interfacing with operating environments
      </CheckBox>
      <CheckBox>
        designing and developing digital animations, imaging, presentations, games, audio and video clips, and Internet applications using multimedia software, tools and utilities, interactive
        graphics and programming languages
      </CheckBox>
      <CheckBox>
        communicating with network specialists regarding web-related issues, such as security and hosting websites, to control and enforce Internet and web server security, space allocation, user
        access, business continuity, website backup and disaster recovery planning
      </CheckBox>
      <CheckBox>
        designing, developing and integrating computer code with other specialized inputs, such as image files, audio files and scripting languages, to produce, maintain and support websites
      </CheckBox>
      <CheckBox>assisting in analyzing, specifying and developing Internet strategies, web-based methodologies and development plans</CheckBox>
    </div>
  );
};

export default MultimediaSpecialist;
