import { createElement } from "react";
import { useParams } from "react-router-dom";

const _generatePage = (name: string) => {
  let page;

  switch (name) {
    case "":
      page = () => require(`./components/HomePage/WelcomPage`).default;
      break;
    case "signup":
      page = () => require(`./components/SignUp/SignUpContainer`).default;
      break;
    case "summary":
      page = () => require(`./components/SummaryPage/Summary`).default;
      break;
    case "thankyou":
      page = () => require(`./components/SignUp/Thankyou`).default;
      break;

    default:
      let route = name.toUpperCase();
      page = () => require(`./components/Questions/${route}`).default;
      break;
  }

  try {
    return createElement(page());
  } catch (error) {
    return (
      <h1 className="flex  justify-center items-center ">Page not Found 404</h1>
    );
  }
};

const PageRender = () => {
  const { page } = useParams();

  let name = "";

  if (page) {
    name = page;
  }

  return _generatePage(name);
};

export default PageRender;
