import {Form, Formik} from "formik";
import {useSelector} from "react-redux";

import * as Yup from "yup";
import "yup-phone";
import {SelectedCardSelector} from "../../feature/Portal/PortalSelector";
import Title from "../AuthPages/PageContainer/Title/Title";
import FormikControl from "../SignUp/FormikControl";

const Profile = () => {
    const data = useSelector(SelectedCardSelector);

    const {firstName, surname, userResponse} = data ?? {
        firstName: "",
        surname: "",
        userResponse: "",
    };
    const initialValues = {
        email: "",
        firstName: "",
        surname: "",
        phone: "",
        age: "",
    };
    const validationSchema = Yup.object({
        email: Yup.string().required("Required"),
        firstName: Yup.string().required("Required"),
        surname: Yup.string().required("Required"),
        phone: Yup.string()
            .required()
            .phone("IN", false, "Please add '+' w/ country code"),
        age: Yup.string().required("Required"),
    });
    const onSubmit = async (values: any) => {
        //console.log(values);
        //navigate("/englang");
    };

    return (
        <div className='py-4'>
            <Title title={"Profile"} />
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}>
                {(formik: any) => {
                    return (
                        <Form className='flex flex-col justify-center items-center space-y-10 px-2'>
                            <div className='w-full  sm:grid sm:grid-cols-2  sm:mt-10'>
                                <div className='pb-4 sm:pb-0 flex flex-col space-y-2'>
                                    <FormikControl
                                        control='inputProfile'
                                        type='text'
                                        label='First Name'
                                        name='firstName'
                                        value={data.firstName}
                                        disabled
                                    />
                                    <FormikControl
                                        control='inputProfile'
                                        type='text'
                                        label='Surname'
                                        name='surname'
                                        value={data.surname}
                                        disabled
                                    />
                                    <FormikControl
                                        control='inputProfile'
                                        type='email'
                                        label='Email Address'
                                        name='email'
                                        value={data.email}
                                        disabled
                                    />
                                    <FormikControl
                                        control='inputProfile'
                                        type='tel'
                                        label='Phone Number'
                                        name='phone'
                                        value={data.phone}
                                        disabled
                                    />

                                    <FormikControl
                                        control='inputProfile'
                                        type='number'
                                        label='Age'
                                        name='age'
                                        value={userResponse.age}
                                        disabled
                                    />
                                    <FormikControl
                                        control='inputProfile'
                                        type='text'
                                        label='Status'
                                        name='status'
                                        value={userResponse.stage}
                                        disabled
                                    />
                                </div>
                                <div className='flex flex-col space-y-4'>
                                    <FormikControl
                                        control='country'
                                        type='text'
                                        label='Country of Citizenship'
                                        name='country'
                                        value={userResponse.countries}
                                        disabled
                                    />
                                    {userResponse?.postAnswer?.currentPoints
                                        ?.englang && (
                                        <FormikControl
                                            control='inputProfile'
                                            type='text'
                                            label='English Competency Level'
                                            name='english'
                                            value={
                                                userResponse?.postAnswer
                                                    ?.currentPoints?.englang
                                                    ?.proficiency
                                            }
                                            disabled
                                        />
                                    )}
                                    <FormikControl
                                        control='inputProfile'
                                        type='text'
                                        label='Work Experience'
                                        name='work'
                                        value={`${userResponse.workExperience} years in your profession or trade`}
                                        disabled
                                    />
                                    <FormikControl
                                        control='textarea'
                                        type='text'
                                        label='Qualification'
                                        name='qualification'
                                        disabled>
                                        Degree or diploma level qualification in
                                        your profession, or have obtained a
                                        recognised trade certification related
                                        to your profession trade
                                    </FormikControl>

                                    {userResponse.profession &&
                                        userResponse.profession.length > 0 && (
                                            <FormikControl
                                                control='textarea'
                                                type='text'
                                                label='Profession'
                                                name='profession'
                                                disabled>
                                                {userResponse.profession.reduce(
                                                    (
                                                        d: any,
                                                        r: any,
                                                        i: any
                                                    ) => {
                                                        if (i === 0)
                                                            return `${r.profession}`;
                                                        else {
                                                            return `${d}\n${r.profession}`;
                                                        }
                                                    },
                                                    ""
                                                )}
                                            </FormikControl>
                                        )}
                                    {userResponse?.postAnswer?.Education && (
                                        <FormikControl
                                            control='inputProfile'
                                            type='text'
                                            label='Education'
                                            name='education'
                                            value={
                                                userResponse?.postAnswer
                                                    ?.Education?.value
                                            }
                                            disabled
                                        />
                                    )}
                                    {userResponse?.postAnswer
                                        ?.Specialisation === "yes" && (
                                        <FormikControl
                                            control='inputProfile'
                                            type='text'
                                            label='Specialisation'
                                            name='specialisation'
                                            value={
                                                userResponse.postAnswer
                                                    .DetailedSpec?.narrow
                                            }
                                            disabled
                                        />
                                    )}
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default Profile;
