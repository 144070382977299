import { useState } from "react";
import EnglishAnswer from "./EnglishAnswer";
import EnglishCourse from "./EnglishCourse";
import { o1, o2, o3, o4, o5 } from "./EnglishOptions";
import EnglishYear from "./EnglishYear";
import { EnglishCourseType } from "./type";

const EnglishContainer = () => {
  //ieltsacademic
  const [value, setValue] = useState<null | string>();
  const [year, setYear] = useState<number | undefined>();

  //ieltsgeneral
  const [value1, setValue1] = useState<null | string>();
  const [year1, setYear1] = useState<number | undefined>();

  //TOEFL
  const [value2, setValue2] = useState<null | string>();
  const [year2, setYear2] = useState<number | undefined>();

  //PTE
  const [value3, setValue3] = useState<null | string>();
  const [year3, setYear3] = useState<number | undefined>();
  //OET
  const [value4, setValue4] = useState<null | string>();
  const [year4, setYear4] = useState<number | undefined>();

  //Cambridge
  const [value5, setValue5] = useState<null | string>();
  const [year5, setYear5] = useState<number | undefined>();

  return (
    <div className="flex flex-col items-center justify-center space-y-6">
      <div className="sm:text-xl lg:text-3xl xl:text-4xl text-justify px-4 sm:text-center flex justify-center items-center text-[#5A5A5A] pb-5 max-w-screen-2xl">
        If you have completed an English language test in the past two years, please select the score you received from the course you completed
      </div>
      <div className="flex flex-col w-[80%] space-y-5">
        <div className="grid grid-cols-6 text-xl xl:items-center">
          <div className="col-span-6 text-center xl:text-left xl:col-span-3 xl:text-xl text-[#5A5A5A] font-bold">Recognized English Language Courses</div>
          <div className="hidden xl:flex xl:col-span-2 xl:text-xl text-[#5A5A5A] font-bold">Select the most applicable answer</div>
          <div className="hidden xl:flex xl:col-span-1 xl:text-xl text-[#5A5A5A] font-bold">Year Completed</div>
        </div>

        <div className="flex flex-col w-full items-center space-y-5">
          <div className="flex flex-col items-center justify-center sm:grid grid-cols-6 xl:items-center xl:w-full space-y-1">
            <div className="col-span-6 xl:col-span-3">
              <EnglishCourse title="International English Language Testing System (IELTS Academic Training)" />
            </div>
            <div className="col-span-6 xl:col-span-2">
              <EnglishAnswer
                id={EnglishCourseType.IELTSAcademic}
                options={o1}
                setValue={setValue}
                value={value ? value : null}
              />
            </div>
            <div className="col-span-6 xl:col-span-1 self-start xl:self-center">
              <EnglishYear
                id={EnglishCourseType.IELTSAcademic}
                setYear={setYear}
                year={year ? year : undefined}
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center sm:grid grid-cols-6 xl:items-center xl:w-full space-y-1">
            <div className="col-span-6 xl:col-span-3">
              <EnglishCourse title="International English Language Testing System (IELTS General Training)" />
            </div>

            <div className="col-span-6 xl:col-span-2">
              <EnglishAnswer
                id={EnglishCourseType.IELTSGeneral}
                options={o1}
                setValue={setValue1}
                value={value1 ? value1 : null}
              />
            </div>

            <div className="col-span-6 xl:col-span-1 self-start xl:self-center">
              <EnglishYear
                id={EnglishCourseType.IELTSGeneral}
                setYear={setYear1}
                year={year1 ? year1 : undefined}
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center sm:grid grid-cols-6 xl:items-center xl:w-full space-y-1 ">
            <div className="col-span-6 xl:col-span-3">
              <EnglishCourse title="Test of English as a Foreign Language internet-based Test (TOEFL iBT)" />
            </div>

            <div className="col-span-6 xl:col-span-2">
              <EnglishAnswer
                id={EnglishCourseType.TOEFL}
                options={o2}
                setValue={setValue2}
                value={value2 ? value2 : null}
              />
            </div>

            <div className="col-span-6 xl:col-span-1 self-start xl:self-center">
              <EnglishYear
                id={EnglishCourseType.TOEFL}
                setYear={setYear2}
                year={year2 ? year2 : undefined}
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center sm:grid grid-cols-6 xl:items-center xl:w-full space-y-1 ">
            <div className="col-span-6 xl:col-span-3">
              <EnglishCourse title="Pearson Test of English Academic (PTE Academic)" />
            </div>

            <div className="col-span-6 xl:col-span-2">
              <EnglishAnswer
                id={EnglishCourseType.PTE}
                options={o3}
                setValue={setValue3}
                value={value3 ? value3 : null}
              />
            </div>

            <div className="col-span-6 xl:col-span-1 self-start xl:self-center">
              <EnglishYear
                id={EnglishCourseType.PTE}
                setYear={setYear3}
                year={year3 ? year3 : undefined}
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center sm:grid grid-cols-6 xl:items-center xl:w-full space-y-1 ">
            <div className="col-span-6 xl:col-span-3">
              <EnglishCourse title="Occupational English Test (OET)" />
            </div>

            <div className="col-span-6 xl:col-span-2">
              <EnglishAnswer
                id={EnglishCourseType.OET}
                options={o4}
                setValue={setValue4}
                value={value4 ? value4 : null}
              />
            </div>

            <div className="col-span-6 xl:col-span-1 self-start xl:self-center">
              <EnglishYear
                id={EnglishCourseType.OET}
                setYear={setYear4}
                year={year4 ? year4 : undefined}
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center sm:grid grid-cols-6 xl:items-center xl:w-full space-y-1 pb-5">
            <div className="col-span-6 xl:col-span-3">
              <EnglishCourse title="Cambridge C1 Academic Advanced test" />
            </div>

            <div className="col-span-6 xl:col-span-2">
              <EnglishAnswer
                id={EnglishCourseType.CAMBRIDGE}
                options={o5}
                setValue={setValue5}
                value={value5 ? value5 : null}
              />
            </div>

            <div className="col-span-6 xl:col-span-1 self-start xl:self-center">
              <EnglishYear
                id={EnglishCourseType.CAMBRIDGE}
                setYear={setYear5}
                year={year5 ? year5 : undefined}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flex flex-col sm:items-center justify-around  space-y-2 px-3 pb-3 sm:flex-row sm:space-x-6 "></div> */}
    </div>
  );
};

export default EnglishContainer;
