import {ReactNode} from "react";

function TextError({children}: {children?: ReactNode}) {
    return (
        <div className='text-red-800 flex justify-center items-center'>
            {children}
        </div>
    );
}

export default TextError;
