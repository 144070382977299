import {faChevronDown, faQuestion} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {motion} from "framer-motion";
import {useEffect, useRef, useState} from "react";
import {Scrollbars} from "react-custom-scrollbars";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {
    buttonModalSelector,
    visibleSelectors,
} from "../../feature/Modal/modalSelector";
import {close, open, saveAge} from "../../feature/Modal/modalSlice";
import {activePage, pushHistory} from "../../feature/QSlice/QSlice";
import {getAge} from "../../generic/generic";
import ArrowComponent from "../Custom/ArrowComponent";
import Modal from "../Modal/Modal";
import "./Q1.css";
import Title from "./Title";
const Q1 = () => {
    const [date, setDate] = useState<Date | null>();
    const navigate = useNavigate();

    const [placeHolder, setPlaceHolder] = useState("Select your birthday");
    const dateRef = useRef<DatePicker>(null);
    const dispatch = useDispatch();
    const visible = useSelector(visibleSelectors);
    const {buttonType} = useSelector(buttonModalSelector);

    const [points, setPoints] = useState(0);

    useEffect(() => {
        dispatch(close());
        dispatch(pushHistory({id: 1, name: "q1"}));
        dispatch(activePage({id: 1, name: "q1"}));
    }, [dispatch]);

    const handleClick = () => {
        dateRef.current?.setFocus();
    };

    const handleFocus = () => {
        setPlaceHolder("You may type here...");
    };

    const openInfoModal = () => {
        dispatch(open({buttonType: "Close"}));
    };

    const handleDateChange = (date: Date) => {
        getPoints(date);
        setDate(date);
    };

    const getPoints = (date: Date) => {
        let payload;

        if (date) {
            payload = getAge(date);
            //console.log(payload);
            if (payload >= 18 && payload < 25) setPoints(25);
            else if (payload >= 25 && payload < 33) setPoints(30);
            else if (payload >= 33 && payload < 40) setPoints(25);
            else if (payload >= 40 && payload < 45) setPoints(15);
        }
    };

    const handleNextPage = () => {
        const value = dateRef.current?.props.selected;
        let age = 0;

        if (!(value === null || value === undefined)) {
            age = getAge(value);
        }

        if (value === null || value === undefined)
            alert("Please select date first");
        else if (age > 44 || age < 22) {
            dispatch(open({buttonType: "Home Page", Q: "/"}));
        } else {
            //dispatch(open({buttonType: "Proceed", Q: "/q2"}));
            dispatch(saveAge(age));
            navigate("/q2");
        }
    };
    return (
        <motion.div
            initial={{
                y: 500,
                x: 0,
                opacity: 0,
            }}
            animate={{
                y: 0,
                x: 0,
                opacity: 1,
            }}
            transition={{
                type: "tween",
                ease: "anticipate",
                duration: 1.5,
            }}
            exit={{
                opacity: 0,
            }}
            className='sm:h-screen flex flex-col items-center space-y-[5px] sm:space-y-[40px]'>
            <Title questionNumber='1' title='Age Qualification' />

            <div className='flex  w-[884px] justify-between flex-col space-y-4 items-center lg:flex-row'>
                <div className='flex flex-row'>
                    <span className='text-[#5A5A5A] text-2xl sm:text-[30px]  sm:pr-4'>
                        When is your birthday?
                    </span>
                    <motion.div
                        whileHover={{
                            scale: 1.1,
                            textShadow: "0px 0px 2px #B08662",
                            boxShadow: "0px 0px 5px #B08662",
                            borderRadius: "9999px",
                        }}
                        className='flex  w-6 h-6 justify-center items-center border border-[#B08662] rounded-full lg:mr-[186px] cursor-pointer'
                        onClick={openInfoModal}>
                        <FontAwesomeIcon
                            icon={faQuestion}
                            className='text-[#B08662]'
                        />
                    </motion.div>
                </div>
                <motion.div className='w-52 sm:w-[316px] relative self-center flex flex-row border border-[#DED1C5] cursor-pointer'>
                    <DatePicker
                        onChange={handleDateChange}
                        selected={date}
                        className='sm:h-[45px] w-full outline-none select-none px-2  sm:text-[24px] text-[#A09FA1] leading-[29px] cursor-pointer'
                        placeholderText={placeHolder}
                        dateFormat={["do MMMM yyyy", "dd/MM/yyyy"]}
                        ref={dateRef}
                        onFocus={handleFocus}
                        showYearDropdown
                        showMonthDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                    />
                    <div
                        className='absolute right-0 w-[30px] sm:w-[45px] h-full bg-[#B08662] flex justify-center items-center'
                        onClick={handleClick}>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className='text-white p-0'
                        />
                    </div>
                </motion.div>
            </div>
            <div className='' onClick={handleNextPage}>
                <ArrowComponent />
            </div>
            <div>
                <Modal isOpen={visible}>
                    <Scrollbars
                        autoHide={false}
                        className='sm:w-[957px] sm:h-[350px] sm:text-2xl mx-[13px] my-0 overflow-hidden mt-[31px] text-[#707070]'>
                        <div className='p-2 font-extrabold'>
                            {buttonType === "Home Page" ? (
                                <>Age Restrictions are not met</>
                            ) : (
                                <>Age Restrictions</>
                            )}
                        </div>
                        <div className='leading-[29px] flex flex-col space-y-10 p-2'>
                            <span>
                                The cut-off age for lodging an independent
                                skilled visa application is 45 years.
                                Additionally, if you are younger than 22, there
                                are work experience and educational requirements
                                you may not meet. Therefore if you are 44 years
                                or older you may not qualify via the Independent
                                Skilled Visa process.
                            </span>
                            <span>
                                However, you may still be eligible to migrate to
                                Australia if an Australian employer is willing
                                to sponsor you. If this is the case, please ask
                                your proposed Australian employer to email us –
                                immigration@australianpresencelegal.com.
                            </span>
                            <span>
                                If you have significant wealth, or own a highly
                                successful business, you may be eligible to
                                migrate to Australia under a business,
                                innovation or investment pathway. If you would
                                like to understand your eligibility for a
                                business, innovation or investment visa, please
                                click
                                <Link to='/investmentpathway'>
                                    <span className='text-[#0316F8] font-semibold'>
                                        {" here."}
                                    </span>
                                </Link>
                            </span>
                        </div>
                    </Scrollbars>
                </Modal>
            </div>
        </motion.div>
    );
};

export default Q1;
