import {createSlice} from "@reduxjs/toolkit";

interface questionType {
    id: number;
    name: string;
}

interface initialStateType {
    navigatedQuestion: questionType[];
    currentQuestion: questionType;
}

const initialState: initialStateType = {
    navigatedQuestion: [],
    currentQuestion: {
        id: 0,
        name: "/",
    },
};

const QSlice = createSlice({
    name: "question",
    initialState,
    reducers: {
        pushHistory: (state, {payload}) => {
            const existingQuestion = state.navigatedQuestion.find(
                (q) => q.id === payload.id
            );
            if (!existingQuestion) {
                state.navigatedQuestion.push(payload);
            }
        },
        activePage: (state, {payload}) => {
            state.currentQuestion = payload;
        },
    },
});

export const {pushHistory, activePage} = QSlice.actions;
export default QSlice.reducer;
