import {motion} from "framer-motion";

import {useEffect, useMemo, useRef, useState} from "react";
import Scrollbars from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    checkSelectedCountries,
    visibleSelectors,
} from "../../feature/Modal/modalSelector";
import {
    close,
    open,
    saveNumOfCountries,
    setEnglish,
} from "../../feature/Modal/modalSlice";
import {activePage, pushHistory} from "../../feature/QSlice/QSlice";
import ArrowComponent from "../Custom/ArrowComponent";
import UseDropdownHook from "../Customhook/DropdownCustomhook";
import Dropdown from "../DropdownMenu/Dropdown/Dropdown";
import Modal from "../Modal/Modal";
import Q2Sub from "./Q2Sub";
import Title from "./Title";

const options = [
    {
        count: 1,
        value: "1 Country",
        label: "I hold citizenship in 1 country",
    },
    {
        count: 2,
        value: "2 Countries",
        label: "I hold citizenship in 2 countries",
    },
    {
        count: 3,
        value: "3 Countries",
        label: "I hold citizenship in 3 countries",
    },
    {
        count: 4,
        value: "4 Countries",
        label: "I hold citizenship in 4 countries",
    },
];

const Q2 = () => {
    const dropdownData = UseDropdownHook();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const visible = useSelector(visibleSelectors);
    const {isExemptCountry, match} = useSelector(checkSelectedCountries);
    const [value1, setValue1] = useState("Select Country");

    /* const openInfoModal = () => {
        dispatch(open({buttonType: "Close"}));
    }; */

    const ref = useRef<HTMLSelectElement>(null);

    useEffect(() => {
        dispatch(close());
        dispatch(pushHistory({id: 2, name: "q2"}));
        dispatch(activePage({id: 2, name: "q2"}));
    }, [dispatch]);

    let countriesOption = useMemo(() => {
        const data = options.find((v) => v.value === dropdownData.value);
        const country = [];
        if (data) {
            for (let i = 0; i < data?.count; i++) {
                country.push(
                    <Q2Sub
                        key={i}
                        selectRef={ref}
                        id={i.toString()}
                        value={value1}
                    />
                );
            }
        } else {
            return null;
        }

        return country;
    }, [dropdownData.value, value1]);

    useEffect(() => {
        setValue1("Select Country");
        if (dropdownData.value) {
            switch (dropdownData.value) {
                case "1 Country":
                    dispatch(saveNumOfCountries(1));

                    break;
                case "2 Countries":
                    dispatch(saveNumOfCountries(2));

                    break;
                case "3 Countries":
                    dispatch(saveNumOfCountries(3));

                    break;
                case "4 Countries":
                    dispatch(saveNumOfCountries(4));

                    break;
            }
        }
    }, [dropdownData.value, dispatch]);

    const handleNextPage = () => {
        if (!dropdownData.value) alert("Please select most applicable");
        else {
            //match is used to check the number of selected coutry is match to the number of input
            if (match) {
                if (isExemptCountry) {
                    dispatch(setEnglish());
                    dispatch(open({buttonType: "Proceed", Q: "/q4"}));
                } else navigate("/q3");
            } else {
                alert("Please select country");
            }
        }
    };

    return (
        <motion.div
            initial={{
                y: 500,
                x: 0,
                opacity: 0,
            }}
            animate={{
                y: 0,
                x: 0,
                opacity: 1,
            }}
            transition={{
                type: "tween",
                ease: "anticipate",
                duration: 1,
            }}
            exit={{
                y: 0,
                y1: 1000,
                opacity: 0,
            }}
            className='h-screen flex flex-col items-center space-y-[10px] sm:space-y-[40px]'>
            <Title questionNumber='2' title='Citizenship' />
            <div className='flex mx-2 sm:mx-12 sm:w-[1274px] justify-center items-center flex-col space-y-7  lg:flex-row lg:justify-around'>
                <div className='flex justify-center  lg:justify-end '>
                    <span className=' text-[#5A5A5A] text-center  text-xl sm:text-[27px] sm:pr-1'>
                        In how many countries do you currently hold citizenship?
                    </span>
                    {/*   <div
                        className='flex w-6 h-6 justify-center items-center border border-[#B08662] rounded-full'
                        onClick={openInfoModal}>
                        <FontAwesomeIcon
                            icon={faExclamation}
                            className='text-[#B08662]'
                        />
                    </div> */}
                </div>

                <Dropdown {...dropdownData} options={options}>
                    Select the most applicable
                </Dropdown>
            </div>
            <div className='relative flex flex-col items-center'>
                {countriesOption && (
                    <div className='flex flex-col  items-center space-y-2 lg:items-start lg:flex-row sm:w-[1157px] lg:space-x-0'>
                        <div className='text-center lg:flex-grow lg:pl-[120px]'>
                            <span className='text-[#5A5A5A] text-xl  sm:text-[27px]  sm:pr-1 lg:mr-40'>
                                Please enter the country you hold citizenship in
                            </span>
                        </div>
                        <div className='flex flex-col lg:justify-end space-y-2 lg:mr-[22px] lg:pr-6'>
                            {countriesOption &&
                                countriesOption?.map(
                                    (SubOption: JSX.Element) => {
                                        return SubOption;
                                    }
                                )}
                        </div>
                    </div>
                )}
                <div className='lg:absolute top-0 ' onClick={handleNextPage}>
                    <ArrowComponent />
                </div>
            </div>

            <div>
                <Modal isOpen={visible}>
                    <Scrollbars
                        autoHide={false}
                        className='w-[957px] h-[350px] sm:text-2xl mx-[13px] my-0 overflow-hidden mt-[31px] text-[#707070]'>
                        <div className='p-2 font-extrabold'>
                            English Language
                        </div>
                        <div className='leading-[29px] flex flex-col space-y-10 p-2'>
                            <span>
                                {`As a citizen of ${isExemptCountry}, you do not need to demonstrate
                                a competent level of English.`}
                            </span>
                        </div>
                    </Scrollbars>
                </Modal>
            </div>
        </motion.div>
    );
};

export default Q2;
