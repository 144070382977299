import {Q2OptionsType} from "../../../Questions/type";

export enum broadOption {
    science = "Natural and Physical Sciences",
    it = "Information Technology",
    engineering = "Engineering and Related Technologies",
}

export const broadOpt: Q2OptionsType[] = [
    {
        label: "Natural and Physical Sciences",
        value: "Natural and Physical Sciences",
    },
    {
        label: "Information Technology",
        value: "Information Technology",
    },
    {
        label: "Engineering and Related Technologies",
        value: "Engineering and Related Technologies",
    },
];
export const ItNarrowOpt: Q2OptionsType[] = [
    {
        label: "Computer Science",
        value: "Computer Science",
    },
    {
        label: "Information Systems",
        value: "Information Systems",
    },
    {
        label: "Information Technology",
        value: "Information Technology",
    },
    {
        label: "Other Information Technology",
        value: "Other Information Technology",
    },
];

export const ScienceNarrowOpt: Q2OptionsType[] = [
    {
        label: "Biological Sciences",
        value: "Biological Sciences",
    },
    {
        label: "Chemical Sciences",
        value: "Chemical Sciences",
    },
    {
        label: "Earth Sciences",
        value: "Earth Sciences",
    },
    {
        label: "Mathematical Sciences",
        value: "Mathematical Sciences",
    },
    {
        label: "Natural and Physical Sciences",
        value: "Natural and Physical Sciences",
    },
    {
        label: "Other Natural and Physical Sciences",
        value: "Other Natural and Physical Sciences",
    },
    {
        label: "Physics and Astronomy",
        value: "Physics and Astronomy",
    },
];

export const EngineeringNarrowOpt: Q2OptionsType[] = [
    {
        label: "Aerospace Engineering and Technology",
        value: "Aerospace Engineering and Technology",
    },
    {
        label: "Civil Engineering",
        value: "Civil Engineering",
    },
    {
        label: "Electrical and Electronic Engineering and Technology",
        value: "Electrical and Electronic Engineering and Technology",
    },
    {
        label: "Engineering and Related Technologies",
        value: "Engineering and Related Technologies",
    },
    {
        label: "Geomatic Engineering",
        value: "Geomatic Engineering",
    },
    {
        label: "Manufacturing Engineering and Technology",
        value: "Manufacturing Engineering and Technology",
    },
    {
        label: "Maritime Engineering and Technology",
        value: "Maritime Engineering and Technology",
    },
    {
        label: "Mechanical and Industrial Engineering Technology",
        value: "Mechanical and Industrial Engineering Technology",
    },
    {
        label: "Other Engineering and Related Technologies",
        value: "Other Engineering and Related Technologies",
    },
    {
        label: "Process and Resources Engineering",
        value: "Process and Resources Engineering",
    },
];
