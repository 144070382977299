import emailjs from "@emailjs/browser";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { doc, updateDoc } from "firebase/firestore";
import { Form, Formik } from "formik";
import { motion } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import * as ReactDOMServer from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "yup-phone";
import { ResponseSelector, UserIDSelector } from "../../../feature/AuthSelector";
import { ProfessionSelector, visibleSelectors } from "../../../feature/Modal/modalSelector";
import { close, open } from "../../../feature/Modal/modalSlice";
import { BroadSpecSelector, ComputeEnglishScore, DetailedSpecSelector, EducationSelector, SpecialisationSelector, TotalPointsSelector } from "../../../feature/PostLogin/PostLoginSelector";
import { RootState } from "../../../feature/store";
import { db } from "../../../firebase";
import Modal from "../../Modal/Modal";
import FormikControl from "../../SignUp/FormikControl";
const Profile = () => {
  const data = useSelector(ResponseSelector);
  const navigate = useNavigate();
  const id = useSelector(UserIDSelector);
  const refBtn = useRef<HTMLButtonElement>(null);
  const postCompetency = useSelector(ComputeEnglishScore);
  const industry = useSelector((state: RootState) => state.post.Industry);
  const profession = useSelector(ProfessionSelector);
  const englang = useSelector((state: RootState) => state.post.currentPoints.englang);
  const totalPoints = useSelector(TotalPointsSelector);
  const education = useSelector(EducationSelector);
  const specialisation = useSelector(SpecialisationSelector);
  const narrowSpecialisation = useSelector(DetailedSpecSelector);
  const broadSpecialisation = useSelector(BroadSpecSelector);
  const specialisationPoints = useSelector((state: RootState) => state.post.currentPoints.specialisationPoints);
  const visible = useSelector(visibleSelectors);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
    firstName: "",
    surname: "",
    phone: "",
    age: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().required("Required"),
    firstName: Yup.string().required("Required"),
    surname: Yup.string().required("Required"),
    phone: Yup.string().required().phone("IN", false, "Please add '+' w/ country code"),
    age: Yup.string().required("Required"),
  });
  const onSubmit = async (values: any) => {
    //console.log(values);
    //navigate("/englang");
  };

  const professionEmail = useMemo(() => {
    return ReactDOMServer.renderToStaticMarkup(
      <div>
        <ul>
          {profession.map((prof) => (
            <li>{prof.profession}</li>
          ))}
        </ul>
      </div>
    );
  }, [profession]);
  const professionDuties = useMemo(() => {
    return ReactDOMServer.renderToString(
      <p style={{ marginBottom: "20px" }}>
        {profession.map((prof: { profession: string; duties: string[] }) => {
          return (
            <div className="mb-2 font-sans">
              <div>
                <u>Profession</u> : {prof.profession}
              </div>
              <div className="flex flex-row">
                <u> Duties</u> :{" "}
                {prof.duties.map((duty) => (
                  <div className="flex flex-col">- {duty}</div>
                ))}
              </div>
            </div>
          );
        })}
      </p>
    );
  }, [profession]);

  const agePoints = useMemo(() => {
    const payload = data.age;

    if (payload >= 18 && payload < 25) return 25;
    else if (payload >= 25 && payload < 33) return 30;
    else if (payload >= 33 && payload < 40) return 25;
    else if (payload >= 40 && payload < 45) return 15;
  }, [data.age]);

  const workExpPoints = useMemo(() => {
    const noOfExperience = data.workExperience;
    if (noOfExperience < 3) return 0;
    else if (noOfExperience >= 3 && noOfExperience < 5) return 5;
    else if (noOfExperience >= 5 && noOfExperience < 8) return 10;
    else if (noOfExperience >= 8) return 15;
  }, [data.workExperience]);

  const onClick = async () => {
    //navigate("/englang");
    setLoading(true);
    const btnType = refBtn.current?.children.item(0)?.firstChild?.nodeValue;
    // console.log(btnType);
    if (btnType) {
      if (btnType === "Continue Application") {
        setLoading(false);
        navigate("/englang");
      }
      if (btnType === "Submit Application") {
        const value = {
          firstname: data.firstName,
          email: data.email,
          phone: data.phone,
          profession: professionEmail,
          age: `${data.age} (${agePoints} points)`,
          proficiency: `${englang.proficiency} (${englang.points} points)`,
          experience: data.workExperience,
          workExpPoints,
          education: `${education?.value} (${education?.points} points)`,

          specialisation: specialisation === "no" || specialisation === undefined ? "N/A" : `${broadSpecialisation} (${specialisationPoints} points)`,

          totalPoints,
          professionDuties,
          address: data.address,
          city: data.city,
          postcode: data.postcode,
          state: data.state,
          industry: industry,
        };
        ////sent to APL
        //console.log(value);
        const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID ?? "";
        const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY ?? "";
        if (process.env.REACT_APP_PROD === "true") {
          await emailjs.send(serviceId, "template_md9on3s", value, publicKey);
          await emailjs.send(serviceId, "template_zvsn1ao", value, publicKey);
          const docRef = doc(db, "UserResponse", id);
          await updateDoc(docRef, {
            dateOfSubmission: new Date(),
            stage: "New",
          });
        }
        setLoading(false);
        dispatch(open({ buttonType: "apl", Q: "/apl" }));
      }
    }
  };

  useEffect(() => {
    dispatch(close());
  }, []);

  return (
    <>
      {!visible && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {(formik: any) => {
            return (
              <Form className="grid grid-cols-6 justify-center items-center w-full xl:max-w-7xl xl:m-auto space-y-5">
                <div className="col-span-6 flex flex-col space-y-4 xl:col-span-3 ">
                  <FormikControl
                    control="inputProfile"
                    type="text"
                    label="First Name"
                    name="firstName"
                    value={data.firstName}
                    disabled
                  />
                  <FormikControl
                    control="inputProfile"
                    type="text"
                    label="Surname"
                    name="surname"
                    value={data.surname}
                    disabled
                  />
                  <FormikControl
                    control="inputProfile"
                    type="email"
                    label="Email Address"
                    name="email"
                    value={data.email}
                    disabled
                  />
                  <FormikControl
                    control="inputProfile"
                    type="tel"
                    label="Phone Number"
                    name="phone"
                    value={data.phone}
                    disabled
                  />

                  <FormikControl
                    control="inputProfile"
                    type="number"
                    label="Age"
                    name="age"
                    value={data.age}
                    disabled
                  />
                </div>
                <div className="col-span-6 flex flex-col space-y-4 xl:col-span-3 ">
                  <FormikControl
                    control="country"
                    type="text"
                    label="Country of Citizenship"
                    name="country"
                    value={data.countries}
                    disabled
                  />
                  <FormikControl
                    control="inputProfile"
                    type="text"
                    label="English Competency Level"
                    name="english"
                    value={postCompetency.proficiency}
                    disabled
                  />
                  <FormikControl
                    control="inputProfile"
                    type="text"
                    label="Work Experience"
                    name="work"
                    value={`${data.workExperience} years in your profession or trade`}
                    disabled
                  />
                  <FormikControl
                    control="textarea"
                    type="text"
                    label="Qualification"
                    name="qualification"
                    disabled
                  >
                    Degree or diploma level qualification in your profession, or have obtained a recognised trade certification related to your profession trade
                  </FormikControl>
                  {profession && profession.length > 0 && (
                    <FormikControl
                      control="textarea"
                      type="text"
                      label="Profession"
                      name="profession"
                      disabled
                    >
                      {profession.reduce((d, r, i) => {
                        if (i === 0) return `${r.profession}`;
                        else {
                          return `${d}\n${r.profession}`;
                        }
                      }, "")}
                    </FormikControl>
                  )}
                  {education && (
                    <FormikControl
                      control="inputProfile"
                      type="text"
                      label="Education"
                      name="education"
                      value={education.value}
                      disabled
                    />
                  )}
                  {specialisation === "yes" && (
                    <FormikControl
                      control="inputProfile"
                      type="text"
                      label="Specialisation"
                      name="specialisation"
                      value={narrowSpecialisation}
                      disabled
                    />
                  )}
                </div>

                {!loading ? (
                  <div className="col-span-6 flex justify-center">
                    <button
                      type="submit"
                      ref={refBtn}
                      className=""
                    >
                      <motion.div
                        role={"presentation"}
                        whileHover={{
                          scale: 1.1,
                          textShadow: "0px 0px 8px rgb(255,255,255)",
                          boxShadow: "0px 0px 8px rgb(255,255,255)",
                          borderRadius: "9999px",
                        }}
                        onClick={onClick}
                        className="text-base py-1 px-2 flex flex-row justify-center font-se items-center sm:p-2 mb-2 sm:w-[230px] bg-[#58061E] opacity-100 text-white sm:text-lg tracking-normal rounded-full cursor-pointer"
                      >
                        {!education ? "Continue Application" : "Submit Application"}
                      </motion.div>
                    </button>
                  </div>
                ) : (
                  <div className="col-span-6 flex justify-center">
                    <div className="w-[230px] flex mb-2 flex-row justify-center items-center p-1 bg-[#58061E] opacity-80 text-white sm:text-lg tracking-normal rounded-full select-none">
                      <svg
                        className="animate-spin h-5 w-5 mr-3"
                        viewBox="0 0 24 24"
                      >
                        <FontAwesomeIcon icon={faSpinner} />
                      </svg>
                      Submitting...
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
      <Modal isOpen={visible}>
        <Scrollbars
          autoHide={false}
          className="sm:w-[957px] sm:h-[350px] sm:text-2xl mx-[13px] my-0 overflow-hidden mt-[31px] text-[#707070]"
        >
          <div className="p-2 font-extrabold">Thank you</div>
          <div className="leading-[29px] flex flex-col space-y-10 p-2">
            <span>
              Thank you for your interest in applying for a skilled independent visa pathway. We have emailed you a copy of your responses to the questionnaire and one of our immigration specialists
              will be in contact with you shortly to arrange a time to discuss the next steps.
            </span>
          </div>
        </Scrollbars>
      </Modal>
    </>
  );
};

export default Profile;
